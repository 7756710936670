@import "./base.less";
@font-face {
  font-family: "DIN";
  src: url("./assets/ttf/D-DIN.woff2") format("woff2"),
    url("./assets/ttf/D-DIN.woff") format("woff"),
    url("./assets/ttf/D-DIN.ttf") format("truetype");
}
* {
  font-family: "DIN", "Roboto" !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "DIN", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mt-10 {
  margin-top: 10px;
}
.templates-wrapper {
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #FFF;
}
@media screen and (max-width: 1200px) {
  .title {
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 64px;
  }

  .sub-title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.56px;
  }
}
@media screen and (max-width: 1280px) {
  body {
    overflow-x: auto;
  }
}

.gradient-text {
  background: linear-gradient(90deg, #e381ff,#5f63e8, #002bff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
::-webkit-scrollbar {
  width: 12px;
  
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 1);
}
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 6px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #e381ff,#5f63e8, #002bff);
  
}
.ant-tooltip {
  background: linear-gradient(180.2deg, rgba(169,133,255,1) -13.48%,rgba(29,59,213,1) 98.93%);
  opacity: 1;
  min-width: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: none;
}
.ant-tooltip .ant-tooltip-content {
  box-shadow: none;
  border: none;
}
.ant-tooltip .ant-tooltip-inner {
  background: transparent;
  padding: 10px 20px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}