/* 变量 Variables */
/* 按钮颜色 */
@btnColor: linear-gradient(90deg, rgba(55, 244, 167, 1) 0%, rgba(64, 159, 237, 1) 50%, rgba(160, 13, 255, 1) 100%);
/* 文字颜色 */
@textColor: linear-gradient(90deg, rgb(55, 244, 167), rgb(64, 160, 238), rgb(158, 13, 255));
/* hover色块颜色 */
@hoverBgColor: linear-gradient(135deg, rgb(55, 244, 167), rgb(64, 159, 237), rgb(160, 13, 255));
/* 主题颜色 */
@themeColor: linear-gradient(90deg, rgba(63, 0, 237, 0), rgba(121, 72, 234, 1), rgba(63, 0, 237, 0));
/* pc最小宽度 */
@pcMinWidth: 1280px;
/* pc最大宽度 */
@pcMaxWidth: 1440px;


/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
    min-width: @pcMinWidth;
}
/* pc最大宽度 */
.max-width {
    max-width: @pcMaxWidth;
}
/* 按钮渐变 */
.btn-gradient {
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, rgba(154, 71, 255, 1), rgba(63, 0, 237, 1));
    border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
    background: linear-gradient(90deg, rgba(169, 133, 255, 1) 0%, rgba(29, 59, 213, 1) 100%);
    border: none;
}
/* 大标题 */
.main-title {
    font-size: 56px;
    font-weight: 1000;
}

// btn类的按钮before样式 做hover渐变按钮
.btn-before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1; /*注意图层前后顺序*/
    transition: all 0.5s;
}
.btn-need-hover {
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;  /*注意图层前后顺序*/ 
    font-weight: bold;
    overflow: hidden;
}