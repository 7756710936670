/* 变量 Variables */
/* 按钮颜色 */
@btnColor: linear-gradient(90deg, rgba(55, 244, 167, 1) 0%, rgba(64, 159, 237, 1) 50%, rgba(160, 13, 255, 1) 100%);
/* 文字颜色 */
@textColor: linear-gradient(90deg, rgb(55, 244, 167), rgb(64, 160, 238), rgb(158, 13, 255));
/* hover色块颜色 */
@hoverBgColor: linear-gradient(135deg, rgb(55, 244, 167), rgb(64, 159, 237), rgb(160, 13, 255));
/* 主题颜色 */
@themeColor: linear-gradient(90deg, rgba(63, 0, 237, 0), rgba(121, 72, 234, 1), rgba(63, 0, 237, 0));
/* pc最小宽度 */
@pcMinWidth: 1280px;
/* pc最大宽度 */
@pcMaxWidth: 1440px;


/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
    min-width: @pcMinWidth;
}
/* pc最大宽度 */
.max-width {
    max-width: @pcMaxWidth;
}
/* 按钮渐变 */
.btn-gradient {
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, rgba(154, 71, 255, 1), rgba(63, 0, 237, 1));
    border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
    background: linear-gradient(90deg, rgba(169, 133, 255, 1) 0%, rgba(29, 59, 213, 1) 100%);
    border: none;
}
/* 大标题 */
.main-title {
    font-size: 56px;
    font-weight: 1000;
}

// btn类的按钮before样式 做hover渐变按钮
.btn-before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1; /*注意图层前后顺序*/
    transition: all 0.5s;
}
.btn-need-hover {
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;  /*注意图层前后顺序*/ 
    font-weight: bold;
    overflow: hidden;
}
@font-face {
  font-family: "DIN";
  src: url(../../static/media/D-DIN.513ab2a5.woff2) format("woff2"),
    url(../../static/media/D-DIN.7d2271b9.woff) format("woff"),
    url(../../static/media/D-DIN.eb09665e.ttf) format("truetype");
}
* {
  font-family: "DIN", "Roboto" !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "DIN", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mt-10 {
  margin-top: 10px;
}
.templates-wrapper {
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #FFF;
}
@media screen and (max-width: 1200px) {
  .title {
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 64px;
  }

  .sub-title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.56px;
  }
}
@media screen and (max-width: 1280px) {
  body {
    overflow-x: auto;
  }
}

.gradient-text {
  background: linear-gradient(90deg, #e381ff,#5f63e8, #002bff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
::-webkit-scrollbar {
  width: 12px;
  
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 1);
}
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 6px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #e381ff,#5f63e8, #002bff);
  
}
.ant-tooltip {
  background: linear-gradient(180.2deg, rgba(169,133,255,1) -13.48%,rgba(29,59,213,1) 98.93%);
  opacity: 1;
  min-width: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: none;
}
.ant-tooltip .ant-tooltip-content {
  box-shadow: none;
  border: none;
}
.ant-tooltip .ant-tooltip-inner {
  background: transparent;
  padding: 10px 20px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
@keyframes slide {
  100% {
    transform: translateX(-50%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cube {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: -50px;
    opacity: 1;
  }
}
.templates-wrapper {
  background-color: #000;
  color: #fff;
  margin: 0 auto;
}
@keyframes slide {
  100% {
    transform: translateX(-50%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes breathing {
  0% {
    box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.7);
  }
  50% {
    box-shadow: 0 0 20px 20px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}
.fadeIn {
  position: relative;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.cube {
  position: relative;
  animation-name: cube;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.breathing {
  transition: background-color 0.5s;
  animation: breathing 0.5s ease-in-out infinite;
}
.wallet-container1-common {
  justify-content: flex-start;
}
.wallet-container1-common .wallet-title {
  margin-top: 234px;
}
.homepage-detial {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-color: #000;
  z-index: 2;
}
.homepage-detial button {
  margin-top: 2rem;
  margin-bottom: 10px;
  text-align: center;
  width: 162px;
  height: 54px;
  opacity: 1;
  border-radius: 30px;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
  font-size: 18px;
  color: #fff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.homepage-detial button:hover {
  color: #000;
}
.homepage-detial button:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.homepage-detial button:hover:before {
  width: 100%;
}
.homepage-detial .wallet-container1 {
  position: relative;
  width: 100%;
  min-height: 1350px;
  z-index: 2;
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.homepage-detial .wallet-container1 .wallet-title {
  margin-top: 234px;
}
.homepage-detial .wallet-container1 .canvas-container {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.homepage-detial .wallet-container1 .canvas-container canvas {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
}
.homepage-detial .wallet-container1 .wallet-title {
  top: 361px;
  width: 100%;
  height: 205px;
  line-height: 100px;
  color: #ffffff;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  font-family: Lato-black;
  z-index: 1;
}
.homepage-detial .wallet-container1 .wallet-title p {
  margin: 0;
  padding: 0;
}
.homepage-detial .wallet-container1 .wallet-addrInput {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  z-index: 1;
  width: 100%;
  max-width: 670px;
}
.homepage-detial .wallet-container1 .wallet-addrInput input {
  padding: 0 24px;
  width: 70%;
  height: 60px;
  line-height: 26px;
  border-radius: 50px;
  background-color: #ffffff;
  color: #888888;
  font-size: 18px;
  text-align: left;
  font-family: Roboto;
  border: 1px solid #bbbbbb;
}
.homepage-detial .wallet-container1 .wallet-addrInput input:active,
.homepage-detial .wallet-container1 .wallet-addrInput input:hover,
.homepage-detial .wallet-container1 .wallet-addrInput input:focus,
.homepage-detial .wallet-container1 .wallet-addrInput input:focus-visible {
  border: 1px solid #bbbbbb;
  box-shadow: none;
  outline: none;
}
.homepage-detial .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
  margin: 0 0 0 21px !important;
  width: 168px;
  height: 60px;
  line-height: 25px;
  border-radius: 30px;
  background-color: #252525;
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid #a985ff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.homepage-detial .wallet-container1 .wallet-addrInput .wallet-addrInput-submit:hover {
  color: #000 !important;
}
.homepage-detial .wallet-container1 .wallet-addrInput .wallet-addrInput-submit:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.homepage-detial .wallet-container1 .wallet-addrInput .wallet-addrInput-submit:hover:before {
  width: 100%;
}
.homepage-detial .wallet-container1 .wallet-addrInput .wallet-addrInput-submit.registed {
  border: none;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: #fff;
}
.homepage-detial .wallet-container1 .check-pass {
  text-align: center;
  margin-top: 12px;
  font-size: 16px;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: transparent;
  font-weight: bold;
}
.homepage-detial .wallet-container1 .wallet-message {
  z-index: 1;
}
.homepage-detial .wallet-container1 .wallet-message p {
  text-align: center;
  margin: 40px 0 0;
  width: 100%;
  height: 25px;
  line-height: 23px;
  color: #ffffff;
  font-size: 20px;
  font-family: PingFangSC-regular;
  font-weight: bold;
}
.homepage-detial .wallet-container2 {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 170px);
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.homepage-detial .wallet-container2 .wallet-card1 {
  width: 634px;
  height: 587px;
  border-radius: 50px;
  background-color: #282828;
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: Roboto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-title {
  width: 100%;
  line-height: 85px;
  height: 85px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  font-family: PingFangSC-bold;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 517px;
  margin: 0 auto 20px;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info p {
  margin: 0;
  padding: 0;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-title {
  height: 39px;
  line-height: 39px;
  color: #fff;
  font-size: 28px;
  text-align: center;
  font-family: PingFangSC-bold;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-rank {
  height: 150px;
  line-height: 150px;
  color: #fff;
  font-size: 100px;
  text-align: center;
  font-weight: bold;
  font-family: Roboto-black;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated {
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated span {
  font-size: 40px;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-message {
  height: 52px;
  line-height: 23px;
  color: #a7a7a7;
  font-size: 20px;
  text-align: center;
  font-family: PingFangSC-regular;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn {
  width: 348px;
  height: 60px;
  line-height: 25px;
  border-radius: 30px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: #fff;
  font-size: 18px;
  text-align: center;
  border: none;
  font-weight: bold;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:active,
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:focus,
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:hover {
  border: none;
  outline: none;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip {
  margin-top: 12px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #646464;
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
}
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip:active,
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip:focus,
.homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip:hover {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}
.homepage-detial .homepage-top-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
  top: 202px;
  font-size: 100px;
  font-weight: 700;
}
.homepage-detial .homepage-top-btn .homepage-btn-title {
  text-align: center;
  font-size: 5rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 5rem;
  -webkit-background-clip: text;
          background-clip: text;
}
.homepage-detial .homepage-top-btn .homepage-btn-desc {
  font-size: 5rem;
  line-height: 5rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}
.homepage-detial .homepage-video {
  height: calc(100vh - 260px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.homepage-detial .homepage-video #hideTop {
  height: calc(100vh - 260px);
  display: flex;
  align-items: center;
}
.homepage-detial .homepage-video .homepage-floor-video {
  margin-top: -150px;
}
.homepage-detial .homepage-floor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  height: auto;
  opacity: 1;
  min-height: 760px;
  transition: opacity 1s linear;
}
.homepage-detial .homepage-floor2 {
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 180px;
  height: 1011px;
  overflow: visible;
}
.homepage-detial .homepage-floor3 {
  position: relative;
  margin-bottom: 110px;
  height: 1463px;
  padding: 0 20px;
}
.homepage-detial .homepage-floor3 .circle-container {
  position: relative;
  height: 609px;
  border-bottom: 2px solid #fff;
  opacity: 0;
}
.homepage-detial .homepage-floor3 .phase-circle {
  position: absolute;
  left: 149px;
  bottom: -12px;
}
.homepage-detial .homepage-floor3 .phase-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 43px;
}
.homepage-detial .homepage-floor3 .phase-container::before {
  position: absolute;
  top: 0;
  content: "";
  background: url(../../static/media/circle.3f2e736c.svg);
}
.homepage-detial .homepage-floor3 .phase-container-item {
  width: 393px;
}
.homepage-detial .homepage-floor3 .phase-container-item-1,
.homepage-detial .homepage-floor3 .phase-container-item-2,
.homepage-detial .homepage-floor3 .phase-container-item-3 {
  opacity: 0;
}
.homepage-detial .homepage-floor3 .phase-container-item-index {
  margin-bottom: 13px;
  font-size: 48px;
  font-weight: 500;
  line-height: 56.25px;
}
.homepage-detial .homepage-floor3 .phase-container-item-title {
  margin-bottom: 41px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 28.13px;
  color: #a6a6a6;
}
.homepage-detial .homepage-floor3 .phase-container-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 106px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.09px;
  color: #d9d9d9;
  opacity: 0;
}
.homepage-detial .homepage-floor3 .homepage-floor3-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.homepage-detial .homepage-floor3 .homepage-floor3-bg canvas {
  position: relative;
  top: 70px !important;
}
.homepage-detial .homepage-floor3 .homepage-floor3-bg video {
  margin-top: 100px;
  height: 400px;
}
.homepage-detial .homepage-floor4 {
  margin-bottom: 240px;
  padding: 0 20px;
}
.homepage-detial .homepage-floor4 .content4-title {
  margin-bottom: 66px;
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 56px;
  font-weight: 1000;
  letter-spacing: 0px;
  line-height: 80px;
  opacity: 0;
}
.homepage-detial .homepage-floor4 .brand-container-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 283px;
  background: linear-gradient(90deg, rgba(63, 0, 237, 0), #7948ea, rgba(63, 0, 237, 0));
}
.homepage-detial .homepage-floor4 .brand-container-row-item {
  z-index: 1;
  opacity: 0;
}
.homepage-detial .homepage-floor4 .brand-container-row img {
  width: 220px;
}
.homepage-detial .homepage-floor4 .brand-container-row::before {
  position: absolute;
  top: 1px;
  bottom: 0px;
  left: 0;
  right: 0;
  background: #000;
  border-radius: 15px;
  content: "";
}
.homepage-detial .homepage-floor4 .brand-container-row:last-child::before {
  bottom: 1px;
}
.homepage-detial .homepage-floor .homepage-swiper-first {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item {
  margin: 4rem auto 0;
  width: 90%;
  height: 440px;
  overflow: hidden;
  background: #1f1f1f;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item:hover .sw-right-title {
  background-clip: text;
  background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-left {
  width: 50%;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-left img {
  border-radius: 15px;
  width: 550px;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right {
  width: 45%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right p {
  width: 100%;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-title {
  font-size: 2rem;
  font-weight: bold;
}
.homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-content {
  font-size: 1rem;
}
.homepage-detial .homepage-floor .homepage-swiper-first .swiper-pagination {
  bottom: 3rem;
}
.homepage-detial .homepage-floor .homepage-swiper-first .swiper-pagination-bullet {
  background-color: #fff;
}
.homepage-detial .homepage-floor .homepage-content1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.homepage-detial .homepage-floor .homepage-content1 .content1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 709px;
  width: 100%;
  z-index: 2;
  padding: 0 20px;
}
.homepage-detial .homepage-floor .homepage-content1 .content1 .content1-title {
  margin-bottom: 29px;
  font-size: 56px;
  font-weight: 1000;
  opacity: 0;
}
.homepage-detial .homepage-floor .homepage-content1 .content1 .content1-desc {
  margin-bottom: 0;
  margin-right: 106px;
  font-size: 18px;
  opacity: 0;
}
.homepage-detial .homepage-floor .homepage-content1 .content1 .presale-btn-button {
  align-self: flex-end;
  opacity: 0;
}
.homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask {
  width: 100%;
  height: 600px;
  position: absolute;
  z-index: 0;
  top: 30px;
  background: transparent;
}
.homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask .homepage-mapbox {
  width: 100%;
  margin: 0 auto;
  height: 975px;
  transition: opacity 1s linear;
  position: static;
  z-index: 0;
}
.homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask .homepage-mapbox .mapboxgl-ctrl-bottom-left {
  display: none;
}
.homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask .homepage-mapbox .mapboxgl-ctrl-bottom-right {
  display: none;
}
.homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask .homepage-mapbox .mapboxgl-canvas-container {
  display: flex;
  justify-content: center;
}
.homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask #homeStarpowerMap {
  width: 100%;
  max-width: 1440px;
  height: 70vh;
  background: transparent;
}
.homepage-detial .homepage-floor .content1-stat {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}
.homepage-detial .homepage-floor .content1-stat-item {
  opacity: 0;
  font-weight: 500;
  letter-spacing: 0px;
}
.homepage-detial .homepage-floor .content1-stat-item-num {
  font-size: 72px;
  font-weight: 1000;
  line-height: 84px;
}
.homepage-detial .homepage-floor .content1-stat-item-title {
  margin-top: 17px;
  font-size: 24px;
  line-height: 28px;
  color: #a6a6a6;
}
.homepage-detial .homepage-floor .homepage-content2 {
  width: 100%;
  margin-top: 180px;
}
.homepage-detial .homepage-floor .homepage-content2 .content2-title {
  margin-bottom: 15px;
  font-size: 56px;
  font-weight: 1000;
  line-height: 70px;
  opacity: 0;
}
.homepage-detial .homepage-floor .homepage-content2 .content2-title span {
  background-clip: text;
  background: linear-gradient(90deg, #00eddd, #479dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homepage-detial .homepage-floor .homepage-content2 .content2-desc {
  margin-bottom: 0;
  font-size: 18px !important;
  line-height: 21px;
  color: #d9d9d9;
  opacity: 0;
}
.homepage-detial .homepage-floor .homepage-content3 {
  width: 100%;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-title {
  font-size: 5rem;
  font-weight: bold;
  display: block;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-title span {
  background-clip: text;
  background: linear-gradient(90deg, #00eddd, #479dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-desc {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 6rem;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block-title {
  font-size: 2rem;
  font-weight: bold;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item {
  cursor: pointer;
  width: 24.5%;
  border-radius: 15px;
  height: 230px;
  background: #121212;
  color: #fff;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 8px 0;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item:hover {
  background: linear-gradient(135deg, #37f4a7, #409fed, #a00dff);
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item p {
  display: block;
  width: 100%;
  margin: 1rem 0;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item p:first-child {
  font-size: 2rem;
  font-weight: bold;
}
.homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item p:last-child {
  font-size: 1.2rem;
  font-weight: normal;
}
.homepage-detial .homepage-floor .homepage-content4 {
  width: 100%;
}
.homepage-detial .homepage-floor .homepage-content4 .content4-title {
  display: block;
}
.homepage-detial .homepage-floor .homepage-content4 .content4-title span {
  background-clip: text;
  background: linear-gradient(90deg, #00eddd, #479dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homepage-detial .homepage-floor .homepage-content4 .content4-desc {
  font-size: 1.2rem;
  display: block;
}
.homepage-detial .homepage-floor .homepage-content4 .content4-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10rem;
}
.homepage-detial .homepage-floor .homepage-content4 .content4-block .content4-block-item {
  cursor: pointer;
  width: 32%;
  border-radius: 15px;
  height: 345px;
  background: #121212;
  color: #fff;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}
.homepage-detial .homepage-floor .homepage-content4 .content4-block .content4-block-item p {
  width: 80%;
  display: block;
  margin: 0;
  font-size: 1rem;
}
.homepage-detial .homepage-partner {
  display: flex;
  overflow: hidden;
  background-color: #1a1a1a;
  height: 100px;
  align-items: center;
  position: relative;
}
.homepage-detial .homepage-partner .homepage-partner-banner {
  display: flex;
  flex: none;
  animation: slide 50s linear infinite;
}
.homepage-detial .homepage-partner .homepage-partner-banner:hover {
  animation-play-state: paused;
}
.homepage-detial .homepage-partner .homepage-partner-title {
  font-size: 3rem;
  font-weight: bold;
  width: 80%;
  padding: 0 24px;
  margin: 0 auto;
}
.homepage-detial .homepage-partner .homepage-partner-list {
  display: inline-block;
  white-space: nowrap;
}
.homepage-detial .homepage-partner .homepage-partner-list .homepage-partner-item {
  display: inline-block;
  height: 60px;
  width: 213px;
  text-align: center;
}
.homepage-detial .homepage-partner .homepage-partner-list .homepage-partner-item img {
  width: 165px;
  height: 60px;
}
.homepage-detial .homepage-testimonials {
  display: block;
  max-width: 1440px;
  overflow: hidden;
  background-color: #000;
  margin-bottom: 214px;
  padding: 3rem 20px 0 20px;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-title {
  display: block;
  width: 80%;
  text-align: left;
  font-size: 56px;
  font-weight: 1000;
  letter-spacing: 0px;
  line-height: 64px;
  color: #fff;
  margin: 0 auto 3rem;
  max-width: 1440px;
  margin: 0 0 82px;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item {
  margin: 0 28px 0 0;
  background-color: #1a1a1a;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc((100% - 56px) / 3);
  height: 277.03px;
  border-radius: 15px;
  background: #000000;
  border: 1px solid #383838;
  text-align: center;
  padding: 25px 36px;
  position: relative;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item:hover {
  background: radial-gradient(80.15% 80.13% at 0% 99.98739794%, #7948ea 0%, #000000 100%);
  border: 1px solid #a6a6a6;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item:first-child,
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item:nth-child(2),
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item:nth-child(3) {
  margin-bottom: 65px;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item:nth-child(3n) {
  margin-right: 0;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item img {
  width: 60px;
  height: 60px;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-info {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.09px;
  color: #808080;
  word-wrap: break-word;
  text-align: left;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-person {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-person img {
  background-color: #808080;
  border-radius: 50%;
  border: none;
  width: 57px;
  height: 55px;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-person .item-person-detail {
  margin-left: 2rem;
  color: #fff;
  text-align: left;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-person .item-person-detail p {
  margin: 0;
  padding: 0;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-person .item-person-detail p:first-child {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.44px;
  color: #ffffff;
}
.homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item .testimonials-item-person .item-person-detail p:last-child {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.44px;
  color: #a6a6a6;
}
.homepage-detial .homepage-events {
  display: block;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  padding: 3rem 0 6rem;
}
.homepage-detial .homepage-events .homepage-events-title {
  display: block;
  width: 80%;
  text-align: left;
  font-size: 5rem;
  font-weight: bold;
  color: #fff;
  margin: 0 auto 3rem;
  max-width: 1440px;
}
.homepage-detial .homepage-events .homepage-events-banner {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-loop {
  display: flex;
  flex: none;
  animation: slide 100s linear infinite;
  height: 550px;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-loop:hover {
  animation-play-state: paused;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list {
  display: flex;
  justify-content: flex-start;
  height: 550px;
  align-items: center;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo {
  width: 350px;
  height: 540px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1rem;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-top-top,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-down-down {
  width: 352px;
  height: 264px;
  border-radius: 15px;
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-top-top p,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-down-down p {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  padding: 0 1.5rem;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-top-top p:first-child,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-down-down p:first-child {
  font-size: 1.4rem;
  font-weight: bold;
  background-clip: text;
  background: linear-gradient(90deg, #43cf7c, #00eddd, #479dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-top-bottom,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-down-top,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-img {
  width: 352px;
  height: 264px;
  border-radius: 15px;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-top-bottom img,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-down-top img,
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-blockTwo .blockTwo-img img {
  border-radius: 15px;
  width: 100%;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-bigimg {
  width: 784px;
  height: 540px;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 1rem;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-bigimg img {
  border-radius: 15px;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-halfImg {
  width: 304px;
  height: 540px;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 1rem;
}
.homepage-detial .homepage-events .homepage-events-banner .homepage-events-list .events-item-halfImg img {
  border-radius: 15px;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.templates-wrapper .homepage-content5 {
  width: 80% !important;
  margin: auto;
}
.templates-wrapper .homepage-content5 .home-page {
  max-width: 100% !important;
}
.templates-wrapper .homepage-content5 .content5-img-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100% !important;
}
.templates-wrapper .homepage-content5 .content5-img-wrapper::before,
.templates-wrapper .homepage-content5 .content5-img-wrapper::after {
  content: none;
}
.templates-wrapper .homepage-content5 .content5-img-wrapper .block {
  width: 25%;
}
.templates-wrapper .homepage-content5 .content5-img-wrapper .content51-a {
  width: 200px;
}
.templates-wrapper .homepage-content5 .content5-img-wrapper .content51-a img {
  max-width: auto;
  height: 60px;
}
@keyframes statistics {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: -50px;
    opacity: 1;
  }
}
.statistics {
  position: relative;
  animation: statistics 0.4s ease-in-out 0s 1 normal forwards;
}
.statistics2 {
  animation-name: statistics;
  animation-delay: 0.4s;
}
.statistics3 {
  animation-name: statistics;
  animation-delay: 0.8s;
}
.statistics4 {
  animation-name: statistics;
  animation-delay: 1.2s;
}
.statistics5 {
  animation-name: statistics;
  animation-delay: 1.6s;
}
@keyframes shiftLeft {
  from {
    opacity: 0;
    left: 150px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
.shiftLeft {
  position: relative;
  animation: shiftLeft 0.7s ease-in-out 0s 1 normal forwards;
}
.shiftLeft2 {
  animation-name: shiftLeft;
  animation-delay: 0.3s;
}
.shiftLeft3 {
  animation-name: shiftLeft;
  animation-delay: 0.6s;
}
@keyframes brandTitle {
  from {
    top: 50px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
.brandTitle {
  position: relative;
  animation: brandTitle 0.3s ease-in-out 0s 1 normal forwards;
}
@keyframes brand {
  from {
    top: 50px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 0.5;
  }
}
.brand {
  position: relative;
  animation: brand 0.2s ease-in-out 0s 1 normal forwards;
}
.brand:hover {
  opacity: 1 !important;
}
.brand2 {
  animation-name: brand;
  animation-delay: 0.2s;
}
.brand3 {
  animation-name: brand;
  animation-delay: 0.4s;
}
.brand4 {
  animation-name: brand;
  animation-delay: 0.6s;
}
.brand5 {
  animation-name: brand;
  animation-delay: 0.8s;
}
@media screen and (min-height: 678px) and (max-height: 999px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .wallet-container1 .wallet-title {
    margin-top: 234px;
  }
  .wallet-container1 .wallet-title {
    top: 200px;
    width: 100%;
    height: 72px;
    line-height: 36px;
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 24px;
    width: 50%;
    height: 60px;
    line-height: 26px;
    border-radius: 50px;
    background-color: #ffffff;
    color: #888888;
    font-size: 18px;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 21px;
    width: 168px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #a985ff;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    padding: 3rem 0;
  }
  .homepage-floor .homepage-content2 .content2-title {
    font-weight: 1000 !important;
  }
  .homepage-floor .homepage-content2 .content2-desc {
    font-size: 1rem !important;
  }
}
@media screen and (min-height: 1000px) and (max-height: 1200px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .wallet-container1 .wallet-title {
    margin-top: 234px;
  }
  .wallet-container1 .wallet-title {
    top: 200px;
    width: 100%;
    height: 90px;
    line-height: 48px;
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 24px;
    width: 50%;
    height: 60px;
    line-height: 26px;
    border-radius: 50px;
    background-color: #ffffff;
    color: #888888;
    font-size: 18px;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 21px;
    width: 168px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #a985ff;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    padding: 3rem 0;
  }
  .homepage-floor .homepage-content2 .home-works {
    height: 70vh !important;
    min-height: 900px;
  }
}
@media screen and (min-width: 2160px) {
  .floor-video-src {
    height: 100%;
    object-fit: cover;
  }
  .homepage-floor .homepage-content2 .home-works {
    height: 70vh !important;
  }
  .homepage-product .homepage-product-content {
    padding-bottom: 2rem;
  }
  .homepage-product .homepage-product-content .homepage-product-info .homepage-info-detail .homepage-detail-item {
    font-size: 1.8rem !important;
  }
  .homepage-product .homepage-product-content .homepage-product-info .homepage-info-detail .homepage-detail-item p {
    height: 3.5rem !important;
    line-height: 3.5rem !important;
  }
  .homepage-product .homepage-product-content .homepage-product-info .homepage-info-detail .homepage-detail-desc {
    font-size: 1.8rem !important;
  }
  .homepage-product .homepage-product-content .homepage-product-info .homepage-info-detail .homepage-detail-desc p {
    line-height: 3rem !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1401px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .wallet-container1 .wallet-title {
    margin-top: 234px;
  }
  .wallet-container1 .wallet-title {
    top: 361px;
    width: 100%;
    height: 120px;
    line-height: 50px;
    color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 24px;
    width: 40%;
    height: 60px;
    line-height: 26px;
    border-radius: 50px;
    background-color: #ffffff;
    color: #888888;
    font-size: 18px;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 21px;
    width: 168px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #a985ff;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wallet-container2 .wallet-card1 {
    width: calc(634px * 0.8) !important;
    height: calc(587px * 0.8) !important;
    border-radius: 50px;
    background-color: #282828;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-title {
    width: 100%;
    line-height: 56px !important;
    height: 56px !important;
    background-color: #a6d5b8;
    color: #fff;
    font-size: 28px;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 517px !important;
    margin: 0 auto 10px;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info p {
    margin: 0;
    padding: 0;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-title {
    height: 36px !important;
    line-height: 36px !important;
    color: #fff;
    font-size: 28px !important;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-rank {
    height: 120px !important;
    line-height: 120px !important;
    color: #fff;
    font-size: 80px !important;
    text-align: center;
    font-weight: bold;
    font-family: Roboto-black;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated {
    height: 40px !important;
    line-height: 40px !important;
    color: #fff;
    font-size: 20px !important;
    text-align: center;
    font-weight: bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated span {
    font-size: 36px !important;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-message {
    height: 52px !important;
    line-height: 23px !important;
    color: #a7a7a7;
    font-size: 20px !important;
    text-align: center;
    font-family: PingFangSC-regular;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px !important;
    width: 100%;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn {
    margin-top: 0 !important;
    width: 40%;
    height: 50px !important;
    line-height: 25px !important;
    border-radius: 25px !important;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 18px;
    text-align: center;
    border: none;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:active,
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:focus,
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container2 .wallet-card1 .card1-option-skip {
    margin: 0 !important;
  }
  .homepage-top-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: calc(100vh - 340px) !important;
  }
  .homepage-top-btn .homepage-btn-desc {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .homepage-top-btn .homepage-btn-title {
    line-height: 3rem !important;
    font-size: 2.5rem !important;
  }
  .homepage-top-btn .homepage-btn-button {
    padding: 10px 30px 10px 30px;
    font-size: 1.2rem !important;
  }
  .homepage-floor-video {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 260px);
    overflow: hidden;
    align-items: center;
    margin-top: 0px !important;
  }
  .homepage-floor-video .floor-video-src {
    width: auto !important;
    height: 100%;
    object-fit: cover;
  }
  .homepage-floor .homepage-floor-desc {
    font-size: 4rem !important;
  }
  .homepage-floor .homepage-floor-desc2 {
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    top: calc(70% + 6rem) !important;
    z-index: 10;
    font-size: 1rem;
    color: #fff;
  }
  .homepage-floor .homepage-swiper-first {
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 6rem;
  }
  .homepage-floor .homepage-swiper-first .sw-item {
    height: 440px !important;
    flex-wrap: wrap;
    margin-top: 5rem !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-left {
    width: 50% !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-left img {
    width: 100% !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right {
    width: 45% !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-title {
    font-size: 1.2rem !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-content {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right button {
    margin-top: 0px !important;
  }
  .homepage-floor .homepage-swiper-first .swiper-pagination {
    bottom: 0 !important;
  }
  .homepage-floor .homepage-content1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .homepage-floor .homepage-content1 .content1-left {
    width: 50% !important;
  }
  .homepage-floor .homepage-content1 .content1-left .content1-left-title {
    font-size: 3rem !important;
    font-weight: bold !important;
  }
  .homepage-floor .homepage-content1 .content1-left .content1-left-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content1 .content1-right {
    margin: 2rem 0 8rem 0;
    width: 45% !important;
  }
  .homepage-floor .homepage-content1 .content1-right img {
    width: 100% !important;
  }
  .homepage-floor .homepage-content1 .homepage-mapbox-mask {
    top: 14vw !important;
  }
  .homepage-floor .homepage-content2 {
    width: 100%;
  }
  .homepage-floor .homepage-content2 .content2-title {
    font-size: 3rem !important;
    font-weight: bold;
  }
  .homepage-floor .homepage-content2 .content2-title span {
    background-clip: text;
    background: linear-gradient(90deg, #00eddd, #479dff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .homepage-floor .homepage-content2 .content2-desc {
    font-size: 1.2rem;
  }
  .homepage-floor .homepage-content3 {
    margin-top: 6rem;
  }
  .homepage-floor .homepage-content3 .content3-title {
    font-size: 3rem !important;
    font-weight: bold !important;
  }
  .homepage-floor .homepage-content3 .content3-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content3 .content3-block {
    margin-top: 2rem !important;
  }
  .homepage-floor .homepage-content3 .content3-block .content3-block-item {
    width: 48% !important;
    height: 180px !important;
  }
  .homepage-floor .homepage-content3 .content3-block .content3-block-item p:first-child {
    font-size: 2rem !important;
  }
  .homepage-floor .homepage-content4 {
    margin-top: 6rem;
  }
  .homepage-floor .homepage-content4 .content4-title {
    font-size: 3rem !important;
    font-weight: bold !important;
  }
  .homepage-floor .homepage-content4 .content4-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content4 .content4-block {
    margin-top: 2rem !important;
  }
  .homepage-testimonials .homepage-testimonials-title {
    width: 90% !important;
    max-width: 90% !important;
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: left;
    font-size: 3rem !important;
    font-weight: bold !important;
  }
  .homepage-events .homepage-events-title {
    width: 90% !important;
    max-width: 90% !important;
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: left;
    font-size: 3rem !important;
    font-weight: bold !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1050px) {
  .homepage-top-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .homepage-top-btn .homepage-btn-desc {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .homepage-top-btn .homepage-btn-title {
    line-height: 3rem !important;
    font-size: 2.5rem !important;
  }
  .homepage-top-btn .homepage-btn-button {
    padding: 10px 30px 10px 30px;
    font-size: 1.2rem !important;
  }
  .homepage-floor {
    width: 90% !important;
    margin: 0 auto;
    height: auto !important;
  }
  .homepage-floor .homepage-floor-desc {
    font-size: 4rem !important;
  }
  .homepage-floor .homepage-floor-desc2 {
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    top: calc(70% + 6rem) !important;
    z-index: 10;
    font-size: 1rem;
    color: #fff;
  }
  .homepage-floor .homepage-swiper-first {
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 6rem;
  }
  .homepage-floor .homepage-swiper-first .sw-item {
    height: 330px !important;
    flex-wrap: wrap;
    margin-top: 8rem !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-left {
    width: 48% !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-left img {
    width: 100% !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right {
    width: 48% !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-title {
    font-size: 1.2rem !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-content {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-swiper-first .sw-item .sw-item-right button {
    margin-top: 0px !important;
  }
  .homepage-floor .homepage-swiper-first .swiper-pagination {
    bottom: 0 !important;
  }
  .homepage-floor .homepage-content1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .homepage-floor .homepage-content1 .content1-left {
    width: 48% !important;
  }
  .homepage-floor .homepage-content1 .content1-left .content1-left-title {
    font-size: 2rem !important;
    font-weight: normal !important;
  }
  .homepage-floor .homepage-content1 .content1-left .content1-left-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content1 .content1-right {
    margin: 2rem 0;
    width: 44% !important;
  }
  .homepage-floor .homepage-content1 .content1-right img {
    width: 100% !important;
  }
  .homepage-floor .homepage-content1 .homepage-mapbox-mask {
    top: 20rem !important;
  }
  .homepage-floor .homepage-content2 .content2-title {
    font-size: 2rem !important;
    font-weight: normal !important;
  }
  .homepage-floor .homepage-content2 .content2-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content3 {
    margin-top: 6rem;
  }
  .homepage-floor .homepage-content3 .content3-title {
    font-size: 2rem !important;
    font-weight: normal !important;
  }
  .homepage-floor .homepage-content3 .content3-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content3 .content3-block {
    margin-top: 2rem !important;
  }
  .homepage-floor .homepage-content3 .content3-block .content3-block-item {
    width: 48% !important;
    height: 180px !important;
  }
  .homepage-floor .homepage-content3 .content3-block .content3-block-item p:first-child {
    font-size: 2rem !important;
  }
  .homepage-floor .homepage-content4 {
    margin-top: 6rem;
  }
  .homepage-floor .homepage-content4 .content4-title {
    font-size: 2rem !important;
    font-weight: normal !important;
  }
  .homepage-floor .homepage-content4 .content4-desc {
    font-size: 1rem !important;
  }
  .homepage-floor .homepage-content4 .content4-block {
    margin-top: 2rem !important;
  }
  .homepage-floor .homepage-content4 .content4-block .content4-block-item {
    width: 32% !important;
  }
  .homepage-testimonials .homepage-testimonials-title {
    width: 90% !important;
    max-width: 90% !important;
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: left;
    font-size: 2rem !important;
    font-weight: normal !important;
  }
  .homepage-events .homepage-events-title {
    width: 90% !important;
    max-width: 90% !important;
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: left;
    font-size: 2rem !important;
    font-weight: normal !important;
  }
}
@media screen and (min-width: 768px) {
  margin: 0 auto;
  max-width: 1440px;
}
@media screen and (max-width: 768px) {
  .homepage-detial .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 190px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .homepage-detial .wallet-container1 video {
    position: relative;
    top: -70px;
    width: 100%;
  }
  .homepage-detial .wallet-container1 .wallet-title {
    margin-top: 46px;
    width: 90%;
    height: 9rem;
    line-height: 3rem;
    color: #ffffff;
    font-size: 2.2rem;
    font-weight: bold;
    text-align: left;
    font-family: Lato-black;
  }
  .homepage-detial .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .homepage-detial .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
    position: relative;
  }
  .homepage-detial .wallet-container1 .wallet-addrInput input {
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    line-height: 3rem;
    border-radius: 2.8rem;
    background-color: #ffffff;
    color: #888888;
    font-size: 1rem;
    text-align: left;
    font-family: Roboto;
    border: none;
    padding-right: 7.2rem;
  }
  .homepage-detial .wallet-container1 .wallet-addrInput input:active,
  .homepage-detial .wallet-container1 .wallet-addrInput input:hover,
  .homepage-detial .wallet-container1 .wallet-addrInput input:focus,
  .homepage-detial .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .homepage-detial .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 0 !important;
    height: 2.8rem;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #fff;
    position: absolute;
    width: 6.5rem;
    right: 5.5%;
  }
  .homepage-detial .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 16px;
    font-family: PingFangSC-regular;
    font-weight: bold;
    text-align: left;
    padding-left: 1.5rem;
  }
  .homepage-detial .wallet-container2 {
    width: 100%;
    height: calc(100vh - 110px);
    min-height: 650px;
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
  .homepage-detial .wallet-container2 .wallet-card1 {
    margin-top: calc((100vh - 480px) / 4);
    width: 95%;
    height: 480px;
    border-radius: 25px;
    background-color: #282828;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-title {
    width: 100%;
    line-height: 4rem;
    height: 4rem;
    background-color: #a6d5b8;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 517px;
    margin: 0 auto 20px;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info p {
    margin: 0;
    padding: 0;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-title {
    height: 1.5rem;
    line-height: 1.5rem;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-rank {
    height: 6rem;
    line-height: 6rem;
    color: #fff;
    font-size: 4rem;
    text-align: center;
    font-weight: bold;
    font-family: Roboto-black;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated {
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated span {
    font-size: 1.2rem;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-message {
    padding: 1rem;
    height: 3rem;
    line-height: 1.5rem;
    color: #a7a7a7;
    font-size: 1rem;
    text-align: center;
    font-family: PingFangSC-regular;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;
    width: 100%;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn {
    width: 40%;
    height: 50px;
    line-height: 25px;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 1rem;
    text-align: center;
    border: none;
    margin-top: 1rem !important;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:active,
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:focus,
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:hover {
    border: none;
    outline: none;
  }
  .homepage-detial .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip {
    margin: 0;
    font-size: 1.2rem;
  }
  .homepage-detial .homepage-top-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: 30.71428571vw;
  }
  .homepage-detial .homepage-top-btn .homepage-btn-desc {
    font-size: 11.9047619vw;
    line-height: 13.92857143vw;
  }
  .homepage-detial .homepage-top-btn .homepage-btn-title {
    font-size: 11.9047619vw;
    line-height: 13.92857143vw;
  }
  .homepage-detial .homepage-top-btn .homepage-btn-button {
    padding: 10px 30px 10px 30px;
    font-size: 1.2rem !important;
  }
  .homepage-detial .homepage-video {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .homepage-detial .homepage-video #hideTop {
    height: calc(100vh - 260px) !important;
    display: flex;
    align-items: center;
  }
  .homepage-detial .homepage-floor-video {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    align-items: center;
    margin-top: -5rem;
  }
  .homepage-detial .homepage-floor-video .floor-video-src {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .homepage-detial .homepage-floor {
    width: 90% !important;
    margin: 0 auto;
    height: auto !important;
  }
  .homepage-detial .homepage-floor .homepage-floor-desc {
    font-size: 4rem !important;
  }
  .homepage-detial .homepage-floor .homepage-floor-desc2 {
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    top: calc(70% + 6rem) !important;
    z-index: 10;
    font-size: 1rem;
    color: #fff;
  }
  .homepage-detial .homepage-floor2 {
    flex-wrap: wrap;
    overflow: visible;
  }
  .homepage-detial .homepage-floor2 .mapboxgl-canvas {
    opacity: 0;
  }
  .homepage-detial .homepage-floor2.fadeIn .mapboxgl-canvas {
    opacity: 1;
  }
  .homepage-detial .homepage-floor3 {
    width: 100% !important;
    overflow: visible;
  }
  .homepage-detial .homepage-floor3 .phase-circle,
  .homepage-detial .homepage-floor3 .circle-container {
    display: none;
  }
  .homepage-detial .homepage-floor3 .phase-container {
    display: block;
    padding-top: 43px;
    border-top: none;
  }
  .homepage-detial .homepage-floor3 .phase-container-item {
    position: relative;
    padding-left: 8.57142857vw;
    padding-top: 5.11904762vw;
    width: 100%;
    height: 15.47619048vw;
    overflow: hidden;
  }
  .homepage-detial .homepage-floor3 .phase-container-item-active {
    height: auto;
  }
  .homepage-detial .homepage-floor3 .phase-container-item-active .phase-container-item-index::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -4.76190476vw;
    width: 2.85714286vw;
    height: 2.85714286vw;
    background: url(/static/media/circle.f367a951.svg) center / 100%;
    translate: 0 -50%;
  }
  .homepage-detial .homepage-floor3 .phase-container-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: -5%;
    right: -5%;
    border-top: 2px solid #383838;
  }
  .homepage-detial .homepage-floor3 .phase-container-item-index {
    position: relative;
    margin-bottom: 5.35714286vw;
    font-size: 2rem;
    font-weight: 500;
    line-height: 6.66666667vw;
  }
  .homepage-detial .homepage-floor3 .phase-container-item-title {
    margin-bottom: 3.45238095vw;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 3.33333333vw;
    color: #a6a6a6;
    font-size: 1.4rem;
  }
  .homepage-detial .homepage-floor3 .phase-container-item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5.23809524vw;
    height: 12.61904762vw;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1;
    color: #d9d9d9;
  }
  .homepage-detial .homepage-floor3 .homepage-floor3-bg {
    position: static;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .homepage-detial .homepage-floor3 .homepage-floor3-bg video {
    margin-top: 0;
    height: 300px;
  }
  .homepage-detial .homepage-floor4 {
    margin-bottom: 26.78571429vw;
    width: 100% !important;
  }
  .homepage-detial .homepage-floor4 .homepage-content4 {
    flex: 1 1;
  }
  .homepage-detial .homepage-floor4 .content4-title {
    margin-bottom: 66px;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 60px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 80px;
  }
  .homepage-detial .homepage-floor4 .brand-container-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    height: 33.69047619vw;
    background: linear-gradient(90deg, rgba(63, 0, 237, 0), #7948ea, rgba(63, 0, 237, 0));
  }
  .homepage-detial .homepage-floor4 .brand-container-row-item {
    flex: 1 0 50%;
    text-align: center;
  }
  .homepage-detial .homepage-floor4 .brand-container-row-item:nth-child(3n + 1) {
    margin: 0;
  }
  .homepage-detial .homepage-floor4 .brand-container-row-item:nth-child(3n + 2) {
    margin: 0;
  }
  .homepage-detial .homepage-floor4 .brand-container-row img {
    width: 36.19047619vw;
    opacity: 0.7;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first {
    width: 100%;
    height: 650px !important;
    margin-top: 0rem;
    margin-bottom: 6rem;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item {
    height: auto !important;
    min-height: 560px;
    flex-wrap: wrap;
    margin-top: 0rem !important;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-left {
    width: 100% !important;
    text-align: center;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-left img {
    width: 100% !important;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right {
    width: 100% !important;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-title {
    font-size: 1.2rem !important;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right .sw-right-content {
    font-size: 1rem !important;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .sw-item .sw-item-right button {
    margin-top: 0px !important;
  }
  .homepage-detial .homepage-floor .homepage-swiper-first .swiper-pagination {
    bottom: 0 !important;
  }
  .homepage-detial .homepage-floor .homepage-content1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 80px;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 {
    margin-bottom: 107.14285714vw;
    width: 100%;
    display: block;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 .content1-title,
  .homepage-detial .homepage-floor .homepage-content1 .content1 .content1-desc {
    width: auto !important;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 .content1-title {
    margin-bottom: 29px;
    font-size: 7vw;
    font-weight: 1000;
    line-height: 1.2;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 .content1-desc {
    margin-bottom: 0;
    font-size: 1rem;
    margin-right: 0;
    line-height: 1.2;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 button {
    font-size: 0.8rem;
    margin-top: 1rem;
    width: 7rem;
    height: 2.5rem;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 button:hover {
    color: #fff;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 button:hover::before {
    width: 0%;
  }
  .homepage-detial .homepage-floor .homepage-content1 .content1 .presale-btn-button {
    align-self: flex-end;
    margin-bottom: 15px;
  }
  .homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask {
    width: 100%;
    height: 90vh;
    position: absolute;
    z-index: 0;
    top: 30px;
    background: transparent;
  }
  .homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask #homeStarpowerMap {
    width: 100%;
    position: relative;
    top: 15rem;
  }
  .homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask #homeStarpowerMap .mapboxgl-canvas-container {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  .homepage-detial .homepage-floor .homepage-content1 .homepage-mapbox-mask #homeStarpowerMap .mapboxgl-canvas-container .mapboxgl-canvas {
    margin-left: -5%;
  }
  .homepage-detial .homepage-floor .content1-stat {
    flex-wrap: wrap;
  }
  .homepage-detial .homepage-floor .content1-stat-item {
    flex-basis: 50%;
    text-align: center;
  }
  .homepage-detial .homepage-floor .content1-stat-item-num {
    line-height: 10vw;
    font-size: 10vw;
  }
  .homepage-detial .homepage-floor .content1-stat-item-title {
    margin-top: 2.02380952vw;
    font-size: 5vw;
    line-height: 3.33333333vw;
  }
  .homepage-detial .homepage-floor .content1-stat-item-title:first-child,
  .homepage-detial .homepage-floor .content1-stat-item-title:nth-child(2) {
    margin-bottom: 9.28571429vw;
  }
  .homepage-detial .homepage-floor .homepage-content2 {
    margin-top: 0;
  }
  .homepage-detial .homepage-floor .homepage-content2 .content2-title {
    width: auto;
    font-size: 7.14285714vw;
    font-weight: 500;
    line-height: 8.33333333vw;
    text-align: center;
  }
  .homepage-detial .homepage-floor .homepage-content2 .content2-desc {
    padding: 0 9.52380952vw;
    width: auto;
    font-size: 2.14285714vw !important;
    line-height: 2.5vw;
  }
  .homepage-detial .homepage-floor .homepage-content3 {
    margin-top: 6rem;
  }
  .homepage-detial .homepage-floor .homepage-content3 .content3-title {
    font-size: 2rem !important;
    font-weight: normal !important;
  }
  .homepage-detial .homepage-floor .homepage-content3 .content3-desc {
    font-size: 1rem !important;
    margin-bottom: 3rem !important;
  }
  .homepage-detial .homepage-floor .homepage-content3 .content3-block-title {
    font-size: 1.4rem !important;
  }
  .homepage-detial .homepage-floor .homepage-content3 .content3-block {
    margin-top: 2rem !important;
  }
  .homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item {
    width: 48% !important;
    height: 180px !important;
  }
  .homepage-detial .homepage-floor .homepage-content3 .content3-block .content3-block-item p:first-child {
    font-size: 2rem !important;
  }
  .homepage-detial .homepage-floor .homepage-content4 {
    margin-top: 6rem;
  }
  .homepage-detial .homepage-floor .homepage-content4 .content4-title {
    margin-bottom: 7.85714286vw;
    font-size: 7.14285714vw;
    font-weight: 1000;
    line-height: 9.52380952vw;
  }
  .homepage-detial .homepage-floor .homepage-content4 .content4-desc {
    font-size: 1rem !important;
  }
  .homepage-detial .homepage-floor .homepage-content4 .content4-block {
    margin-top: 2rem !important;
  }
  .homepage-detial .homepage-floor .homepage-content4 .content4-block .content4-block-item {
    width: 100% !important;
  }
  .homepage-detial .homepage-testimonials {
    margin-bottom: 38.0952381vw;
    padding-bottom: 0;
  }
  .homepage-detial .homepage-testimonials .homepage-testimonials-title {
    width: 100%;
    margin: 0 0 12.38095238vw 8.57142857vw;
    font-size: 7.14285714vw;
    font-weight: 500;
    line-height: 7.61904762vw;
  }
  .homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list {
    justify-content: center;
  }
  .homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item {
    margin: 0 0 10vw;
    width: 90vw !important;
    padding: 1rem 1.2rem !important;
  }
  .homepage-detial .homepage-testimonials .homepage-testimonials-banner .homepage-testimonials-list .testimonials-list-item:last-child {
    margin-bottom: 0;
  }
  .homepage-detial .homepage-events .homepage-events-title {
    width: 90% !important;
    max-width: 90% !important;
    margin: 0 auto 1rem !important;
    padding: 0 !important;
    text-align: left;
    font-size: 2rem !important;
    font-weight: normal !important;
  }
}
#collection-component-1710943570875 {
  height: 5rem;
}

/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.reset-pwd {
  display: flex;
  justify-content: center;
  color: #FFF;
}
.reset-pwd .reset-body {
  margin-top: 120px;
  margin-bottom: 6rem;
  width: 400px;
  color: #FFF;
}
.reset-pwd .reset-body label {
  color: #FFF;
}
.reset-pwd .confirm-btn {
  margin-top: 24px;
  text-align: center;
}
.reset-pwd .confirm-btn .submit-btn {
  background: linear-gradient(90deg, #37f4a7 0%, #409fed 50%, #a00dff 100%);
  color: #000;
  border: none;
  line-height: 32px;
}
.reset-pwd .confirm-btn .submit-btn:hover {
  opacity: 0.7;
}
.reset-pwd .confirm-btn .submit-btn:active {
  opacity: 0.5;
}
@media screen and (max-width: 767px) {
  .reset-pwd {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  .reset-pwd .reset-body {
    margin-top: 120px;
    margin-bottom: 2rem;
    max-width: 600px;
  }
}

/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.product-page {
  position: relative;
  padding-top: 118px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}
.product-page .product-title {
  margin-top: -6px;
  margin-bottom: 56px;
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  color: #ffffff;
}
.product-page .product-sub-title {
  height: 47px;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  color: #a6a6a6;
}
.product-page .video-container {
  position: relative;
  margin: 0 auto 125px;
  max-width: 1440px;
  width: 100%;
}
.product-page .video-container video {
  width: 100%;
  max-height: 100%;
  border-radius: 20px;
}
.product-page .product-item-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-page .product-item-wrapper .product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 163px;
  width: 33.33%;
  min-width: 350px;
}
.product-page .product-item-wrapper .product-item img {
  margin-bottom: 34px;
  width: 100%;
  height: 348px;
  border-radius: 20px;
  object-fit: cover;
  padding: 0 10px;
}
.product-page .product-item-wrapper .product-item .product-name {
  margin-bottom: 18px;
  font-size: 36px;
  font-weight: 1000;
  line-height: 42px;
}
.product-page .product-item-wrapper .product-item .product-desc {
  margin-bottom: 22px;
  font-size: 18px;
  color: #a6a6a6;
}
.product-page .product-item-wrapper .product-item .product-price {
  margin-bottom: 22px;
  height: 28px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}
.product-page .product-btn {
  width: 162px;
  line-height: 54px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  border-radius: 30px;
  height: 54px;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.product-page .product-btn:hover {
  color: #000;
}
.product-page .product-btn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.product-page .product-btn:hover:before {
  width: 100%;
}
.product-page .product-btn-primary {
  border: none;
  border: solid 1px transparent;
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: 1px solid  transparent;
  background-image: linear-gradient(to right, #a985ff, #1e3dd6), linear-gradient(90deg, #9a47ff, #3f00ed);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.product-page .product-btn-primary:hover {
  color: #000;
}
.product-page .product-btn-primary:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.product-page .product-btn-primary:hover:before {
  width: 100%;
}
.product-page .product-btn-disabled {
  cursor: default;
}
.product-page .play-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -55px auto 0;
  width: 110px;
  height: 110px;
  background: #fff;
  border-radius: 55px;
  cursor: pointer;
}
.product-page .play-btn .border {
  top: 38px;
  left: 40px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 19px 31px;
  border-color: transparent transparent #252334;
  position: relative;
  rotate: 90deg;
}
.product-page .play-btn .border:after {
  content: '';
  border-style: solid;
  border-width: 0px 13px 21px;
  border-color: transparent transparent #fff;
  position: absolute;
  top: 6px;
  left: -13px;
}
@media screen and (min-width: 769px) and (max-width: 1049px) {
  .product-page .product-item-wrapper .product-item {
    width: 50% !important;
  }
  .product-page .product-item-wrapper .product-item:last-child {
    width: 100% !important;
  }
  .product-page .product-item-wrapper .product-item:last-child img {
    max-width: auto !important;
  }
}
@media screen and (max-width: 768px) {
  .product-page .product-item-wrapper .product-item {
    width: 100% !important;
  }
  .product-page .product-sub-title {
    height: auto;
  }
  .product-page .product-title {
    font-size: 2.2rem;
    line-height: 1;
    margin-top: 0;
  }
  .product-page .product-item-wrapper .product-item {
    margin-bottom: 100px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.ant-menu-item > .item-sub-item > .lvtxx0zl9y-editor_css {
  color: #FFF;
}
.ant-menu-submenu > .ant-menu > .ant-menu-item {
  display: block;
}
.ant-menu-submenu-popup {
  overflow: visible;
  border-radius: 8px;
}
.ant-menu-submenu-popup .ant-menu-item.ant-menu-item-selected {
  background-color: transparent;
}
#Nav0_0 {
  height: 170px;
}
#Nav0_0.lvu02jzfxlb-editor_css {
  color: #FFF;
  background-color: #000000;
}
#Nav0_0 .home-page > .lvu054404cb-editor_css {
  background-color: #000000;
}
.ant-menu-item > .item-sub-item > .lvtzyt1fkzl-editor_css {
  font-size: 18px;
  font-weight: normal;
  line-height: 1rem;
  text-decoration: none;
}
.ant-menu-item > .item-sub-item > .lvtzynsln4s-editor_css {
  font-size: 18px;
  text-decoration: none;
  margin: 1.5rem 0;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .lvu08riitc-editor_css {
  color: #FFF;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .lvu08ulsiw-editor_css {
  color: #FFF;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .lvu081gtuqs-editor_css {
  color: #FFF;
}
#Nav0_0 .header0-menu > .ant-menu > .lvu046fku8-editor_css {
  background-color: #000000;
}
#Nav0_0 .header0-menu > .ant-menu > .lvu09ws29k-editor_css {
  background-color: #000000;
  font-weight: 500;
}
#Nav0_0 .header0-menu > .ant-menu > .lvu07qzvyqg-editor_css {
  background-color: #000000;
}
#Nav0_0 .ant-menu-submenu-title > .header0-item-block > .lvu0aa9gxvr-editor_css:hover {
  color: #FFF !important;
}
.lvu0aa9gxvr-editor_css p {
  font-size: 18px;
  margin-bottom: 0 !important;
  height: 70px;
}
#Nav0_0 .header0-menu > .ant-menu > .ant-menu-submenu-open {
  color: #ffffff;
}
#Nav0_0 .header0-menu > .ant-menu > .ant-menu-submenu-open p {
  height: 70px;
  border-bottom: solid 2px #fff;
}
.ant-menu > .ant-menu-item > .item-sub-item {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}
.ant-menu > .ant-menu-item > .item-sub-item p {
  margin: 1rem 0;
}
.ant-menu-item > .lvtzx1fse4-editor_css > .lvtzynsln4s-editor_css {
  font-weight: normal;
  color: #ffffff;
}
.ant-menu-item > .lvtzx1fse4-editor_css > .lvtzynsln4s-editor_css:hover p > span {
  padding: 2px 0;
  border-bottom: solid 2px #fff;
}
.ant-menu-item > .lvtzx1fse4-editor_css > .lvtzyt1fkzl-editor_css {
  margin: 1.5rem 0;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
}
#Nav0_0 .header0-menu > .ant-menu > .lvu022uivs-editor_css {
  color: #ffffff;
  background-color: #000000;
  border-radius: 0;
  font-weight: 500;
  width: 100%;
  margin-bottom: 0;
  font-size: 1.2rem !important;
}
#Nav0_0 .header0-menu > .ant-menu > .lvu022uivs-editor_css .ant-menu-submenu-arrow {
  display: none;
}
#Nav0_0 .header0-menu > .ant-menu > .lvu022uivs-editor_css:active {
  color: #ffffff;
}
#Nav0_0 .header0-menu > .ant-menu > .lvu022uivs-editor_css:focus {
  color: #ffffff;
}
.ant-menu-submenu > .ant-menu > .lvtzvlvhhap-editor_css {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #ffffff;
  border-radius: 8px;
  margin: 0px 0px;
}
.ant-menu > .ant-menu-item > .lvtzx1fse4-editor_css {
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
}
@keyframes topBannerAnimation {
  0% {
    background-position: 0 33%;
    background-size: 200% 200%;
  }
  50% {
    background-position: 33% 67%;
    background-size: 200% 200%;
  }
  100% {
    background-position: 66% 0%;
    background-size: 200% 200%;
  }
}
.header-message {
  position: fixed;
  width: 100%;
  height: 68px;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  line-height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: topBannerAnimation 2s ease infinite alternate;
  background: linear-gradient(90deg, #e381ff, #5f63e8, #002bff);
  z-index: 9999;
}
.header-message button {
  width: 186px;
  text-align: center;
  line-height: 42px;
  margin-left: 10%;
  height: 46px;
  font-size: 18px;
  color: #FFF;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
  border-width: 2px;
  border: 2px solid transparent;
  border-radius: 50px;
  padding: 0;
}
.header0 {
  width: 100%;
  overflow: hidden;
  box-shadow: none;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  z-index: 9999;
  position: relative;
  top: 68px !important;
  padding: 0 20px;
}
.header0 .home-page {
  padding: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  position: relative;
  margin: auto;
  will-change: transform;
  z-index: 99;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 251px;
  line-height: 100px;
  cursor: pointer;
}
.header0-logo img {
  position: absolute;
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: flex;
  align-items: center;
  height: 90px;
}
.header0-menu {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header0-menu .ant-menu {
  color: #fff;
  line-height: 90px;
  height: 90px;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header0-menu .ant-menu a {
  display: block;
  color: #fff;
  width: 100%;
  height: 30px;
  margin-bottom: 0px;
  line-height: 30px;
  font-size: 18px;
}
.header0-item-block {
  padding: 0px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item .ant-menu-sub,
.header0-item-child .ant-menu-sub,
.header0-menu .ant-menu-sub {
  position: relative;
  bottom: 30px;
  background: #000;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 4px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
  margin-left: 46px;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
.header0 .moreBtn {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header0 .moreBtn .shopBtn {
  box-shadow: none !important;
  opacity: 1;
  text-align: center;
  width: 160px;
  height: 42px;
  border-radius: 30px;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
  color: #ffffff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.header0 .moreBtn .shopBtn:hover {
  color: #000;
}
.header0 .moreBtn .shopBtn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.header0 .moreBtn .shopBtn:hover:before {
  width: 100%;
}
.header0 .moreBtn .shopBtn:last-child {
  margin-left: 20px;
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
.header0 .moreBtn .shopBtn:last-child:hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
.download-modal {
  color: #FFF !important;
  background: #161616;
  border-radius: 15px;
  overflow: hidden;
}
.download-modal .ant-modal-content {
  background: #161616;
}
.download-modal .ant-modal-close-icon {
  color: #FFF;
}
.download-modal .download-modal-content {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #161616;
}
.download-modal .download-modal-content p {
  text-align: center;
  display: block;
  width: 100%;
}
.download-modal .download-modal-content p:first-child {
  font-size: 2rem;
  font-weight: bold;
}
.download-modal .download-modal-content .download-modal-title {
  background-clip: text;
  background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.download-modal .download-modal-content .download-modal-logo {
  width: 150px;
  margin-bottom: 2rem;
}
.download-modal .download-modal-content .download-qrcode-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}
.download-modal .download-modal-content .download-modal-qrcode {
  width: 150px;
  margin-bottom: 1rem;
  border-radius: 15px;
  background-color: #FFF;
  padding: 10px;
}
.NFT-apply {
  color: #FFF !important;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}
.NFT-apply .ant-modal-header {
  border-radius: 20px 20px, 0px, 0px;
  background: linear-gradient(90deg, #a985ff 0%, #1e3dd6 100%);
  height: 88px;
  padding: 0;
  line-height: 88px;
  text-align: center;
  border-bottom: 0;
}
.NFT-apply .ant-modal-header .ant-modal-title {
  color: #FFF;
  font-size: 24px;
  line-height: 88px;
}
.NFT-apply .ant-modal-content {
  color: #FFF;
  box-shadow: none;
  background: #282828;
}
.NFT-apply .ant-modal-content .ant-modal-body {
  box-shadow: none;
  padding: 43px;
}
.NFT-apply .NFT-modal-content {
  color: #FFF;
}
.NFT-apply .NFT-modal-content .NFT-info {
  text-align: center;
  font-size: 16px;
  margin: -20px 0 20px 0;
}
.NFT-apply .NFT-modal-content label {
  color: #FFF;
}
.NFT-apply .NFT-modal-content .ant-input {
  color: #FFF;
  border: none;
  border-radius: 10px;
  width: 435px;
  height: 50px;
  background: #636363;
  opacity: 1;
  font-size: 16px;
}
.NFT-apply .NFT-modal-content .ant-input:focus {
  box-shadow: none;
}
.NFT-apply .NFT-footer {
  color: #a6a6a6;
  font-size: 16px;
}
.NFT-apply .ant-form-item-required::before {
  content: '';
}
.NFT-apply .ant-form-item-required::after {
  content: '';
}
.NFT-apply .ant-form label {
  font-size: 16px;
}
.NFT-apply .help {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: #2a82e4;
  text-align: left;
  position: absolute;
  top: -50px;
  right: 0;
  cursor: pointer;
}
.NFT-apply .NFT-keys {
  margin-top: 10px;
  font-size: 16px;
}
.NFT-apply .confirm-btn {
  margin-top: 46px;
  text-align: center;
}
.NFT-apply .confirm-btn .ant-btn {
  width: 160px;
  height: 42px;
  border-radius: 30px;
  background: #000000;
  border: 1px solid  transparent;
  color: #fff;
  font-size: 16px;
}
.NFT-apply .confirm-btn .cancel-btn {
  border-radius: 30px;
  width: 160px;
  height: 42px;
  font-size: 16px;
  border: 1px solid  transparent;
  background-color: #000000;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  color: #ffffff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.NFT-apply .confirm-btn .cancel-btn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.NFT-apply .confirm-btn .cancel-btn:hover:before {
  width: 100%;
}
.NFT-apply .confirm-btn .cancel-btn:hover {
  color: #000;
}
.NFT-apply .confirm-btn .submit-btn {
  border-radius: 30px;
  width: 160px;
  height: 42px;
  font-size: 16px;
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  color: #ffffff;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 1px solid  transparent;
  background-image: linear-gradient(to right, #a985ff, #1e3dd6), linear-gradient(90deg, #9a47ff, #3f00ed);
}
.NFT-apply .confirm-btn .submit-btn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.NFT-apply .confirm-btn .submit-btn:hover:before {
  width: 100%;
}
.NFT-apply .confirm-btn .submit-btn:hover {
  color: #000;
}
.wallet-modal {
  border-radius: 25px;
  overflow: hidden;
}
.wallet-modal .ant-modal-content {
  border-radius: 25px;
  background-color: #282828;
  overflow: hidden;
}
.wallet-modal .ant-modal-content .ant-modal-close-x {
  color: #FFF;
  font-weight: bold;
}
.wallet-modal .ant-modal-content .ant-modal-header {
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  border: none;
  text-align: center;
}
.wallet-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
}
.wallet-modal .ant-modal-content .ant-modal-body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}
.wallet-modal .ant-modal-content .ant-modal-body .wallet-modal-body {
  width: 100%;
}
.wallet-modal .ant-modal-content .ant-modal-body .wallet-modal-body .wallet-btn {
  padding: 15px;
  margin: 12px 0 ;
  height: 80px;
  line-height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 15px;
  background-color: #454545;
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
  cursor: pointer;
}
.wallet-modal .ant-modal-content .ant-modal-body .wallet-modal-body .wallet-btn img {
  border-radius: 15px;
  overflow: hidden;
  width: 56px;
  height: 56px;
  margin-right: 18px;
}
@media screen and (max-width: 767px) {
  .opacity {
    opacity: 0 !important;
  }
  .header-message {
    height: 56px;
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    line-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation: topBannerAnimation 2s ease infinite alternate;
    background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
    padding: 0;
  }
  .header-message button {
    width: 100px;
    text-align: center;
    line-height: 42px;
    margin-left: 0.5rem;
    height: 46px;
    font-size: 0.9rem;
    color: #FFF;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #42cf7a, #00eddd, #479dff);
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 0;
  }
  .header0 {
    height: 100px;
    width: 100%;
    top: 56px !important;
  }
  .header0-logo {
    position: absolute;
    z-index: 101;
  }
  .header0 .ant-menu-submenu-title {
    margin-bottom: 1.6rem;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
    width: 100%;
    background-color: #000;
  }
  .header0.home-page-wrapper .home-page .header0-item-block {
    margin-bottom: 1.6rem !important;
  }
  .header0-menu {
    position: relative;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    top: 90px;
  }
  .header0-menu li {
    padding: 0 12px;
    width: 100%;
    display: block;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-menu .item-sub-item a {
    height: 40px;
    line-height: 40px;
    display: block;
    font-weight: 500;
    color: #fff;
    width: 100%;
    height: 30px;
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 1.25em !important;
  }
  .header0-menu .item-sub-item a.title {
    margin: 8px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: -24px !important;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 90%;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
  }
  .header0 .ant-menu .ant-menu-hidden {
    display: none;
  }
  .header0 .ant-menu.ant-menu-sub {
    background: transparent;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    justify-content: flex-start !important;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: fixed !important;
    height: calc(100% - 80px);
    z-index: 999;
    background: #1f1f1f;
  }
  .header0 .open .ant-menu-item-selected {
    background-color: transparent;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0 .open p {
    border-bottom: none !important;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 42px;
    right: 24px !important;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff !important;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .download-modal {
    color: #FFF !important;
    background: #161616;
    border-radius: 15px;
    overflow: hidden;
  }
  .download-modal .ant-modal-content {
    background: #161616;
  }
  .download-modal .ant-modal-close-icon {
    color: #FFF;
  }
  .download-modal .download-modal-content {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #161616;
  }
  .download-modal .download-modal-content p {
    text-align: center;
    display: block;
    width: 100%;
  }
  .download-modal .download-modal-content p:first-child {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .download-modal .download-modal-content .download-modal-title {
    background-clip: text;
    background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .download-modal .download-modal-content .download-modal-logo {
    width: 150px;
    margin-bottom: 2rem;
  }
  .download-modal .download-modal-content .download-qrcode-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .download-modal .download-modal-content .download-modal-qrcode {
    width: 120px;
    margin-bottom: 1rem;
    border-radius: 15px;
    background-color: #FFF;
    padding: 10px;
  }
  .header0 .moreBtn {
    width: 300px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
  }
  .header0 .moreBtn .shopBtn {
    cursor: pointer;
    box-shadow: none !important;
    color: #000000;
    font-weight: bold;
    font-size: 1.1rem;
    width: 57.61904762vw;
    opacity: 1;
    border-radius: 100px;
    background: #000000;
    background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
    border: solid 1px transparent;
    color: #ffffff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    font-weight: normal;
  }
  .header0 .moreBtn .shopBtn:hover {
    color: #fff;
  }
  .header0 .moreBtn .shopBtn:hover:before {
    width: 0%;
  }
  .header0 .moreBtn .shopBtn:last-child {
    margin-left: 0;
    background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  }
  .NFT-apply .NFT-modal-content .ant-input {
    width: 100%;
  }
  .NFT-apply .confirm-btn .cancel-btn,
  .NFT-apply .confirm-btn .submit-btn {
    width: 6rem;
    font-weight: normal;
  }
  .NFT-apply .confirm-btn .cancel-btn:hover,
  .NFT-apply .confirm-btn .submit-btn:hover {
    color: #fff;
  }
  .NFT-apply .confirm-btn .cancel-btn:hover:before,
  .NFT-apply .confirm-btn .submit-btn:hover:before {
    width: 0%;
  }
}
@media screen and (min-width: 767px) {
  .header0 {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .header0 {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;
    width: 100%;
  }
  .header-message {
    height: 68px;
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    line-height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation: topBannerAnimation 2s ease infinite alternate;
    background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
  }
  .header-message button {
    width: 120px;
    text-align: center;
    line-height: 42px;
    margin-left: 0.5rem;
    height: 46px;
    font-size: 1rem;
    color: #FFF;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #42cf7a, #00eddd, #479dff);
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 0;
  }
  .header0 {
    width: 100%;
    overflow: hidden;
    box-shadow: none;
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    z-index: 99;
    top: 68px !important;
    padding: 0 20px;
  }
  .header0 .home-page {
    padding: 0 24px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    position: relative;
    margin: auto;
    will-change: transform;
    z-index: 99;
  }
  .header0-logo {
    display: inline-block;
    position: relative;
    width: 200px;
    line-height: 100px;
    cursor: pointer;
  }
  .header0-logo img {
    vertical-align: middle;
    display: inline-block;
  }
  .header0-logo a {
    display: flex;
    align-items: center;
    height: 100px;
  }
  .header0-menu {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header0-menu .ant-menu {
    color: #fff;
    line-height: 98px;
    height: 100px;
    background: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header0-menu .ant-menu a {
    display: block;
    font-weight: 500;
    color: #fff;
    width: 100%;
    height: 30px;
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 1.2rem !important;
  }
  .header0-item-block {
    padding: 0px;
  }
  .header0-item-block > * {
    display: inline-block;
  }
  .header0-item .ant-menu-sub .ant-menu-item,
  .header0-item-child .ant-menu-sub .ant-menu-item,
  .header0-menu .ant-menu-sub .ant-menu-item,
  .header0-item .ant-menu-inline .ant-menu-item,
  .header0-item-child .ant-menu-inline .ant-menu-item,
  .header0-menu .ant-menu-inline .ant-menu-item {
    height: auto;
    line-height: 1.5;
  }
  .header0-item .item-sub-item,
  .header0-item-child .item-sub-item,
  .header0-menu .item-sub-item {
    display: block;
    padding: 8px 24px;
  }
  .header0-item .item-image,
  .header0-item-child .item-image,
  .header0-menu .item-image {
    float: left;
    margin-right: 16px;
    margin-top: 4px;
    position: relative;
    z-index: 1;
  }
  .header0-item .item-title,
  .header0-item-child .item-title,
  .header0-menu .item-title {
    font-size: 14px;
    color: #fff;
    margin-left: 46px;
  }
  .header0-item .item-content,
  .header0-item-child .item-content,
  .header0-menu .item-content {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    margin-left: 46px;
  }
  .header0 .moreBtn {
    width: 220px !important;
    display: none !important;
    justify-content: space-between;
    align-items: center;
  }
  .header0 .moreBtn .shopBtn {
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer;
    border: none !important;
    box-shadow: none !important;
    opacity: 1;
    font-weight: bold;
    font-size: 0.8rem !important;
    width: 100px !important;
    height: 46px;
    line-height: 46px;
    border-radius: 25px;
    text-align: center;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px !important;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff !important;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .header0 {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    overflow: hidden;
    box-shadow: none;
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    z-index: 9999;
  }
  .header0 .home-page {
    padding: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .header0-logo {
    display: inline-block;
    position: relative;
    width: 200px;
    line-height: 100px;
    cursor: pointer;
  }
  .header0-logo img {
    vertical-align: middle;
    display: inline-block;
  }
  .header0-logo a {
    display: flex;
    align-items: center;
    height: 100px;
  }
  .header0-menu {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header0-menu .ant-menu {
    color: #fff;
    line-height: 98px;
    height: 100px;
    background: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header0-menu .ant-menu a {
    display: block;
    font-weight: 500;
    color: #fff;
    width: 100%;
    height: 30px;
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 1.2rem !important;
  }
  .header0-item-block {
    padding: 0px;
  }
  .header0-item-block > * {
    display: inline-block;
  }
  .header0-item .ant-menu-sub .ant-menu-item,
  .header0-item-child .ant-menu-sub .ant-menu-item,
  .header0-menu .ant-menu-sub .ant-menu-item,
  .header0-item .ant-menu-inline .ant-menu-item,
  .header0-item-child .ant-menu-inline .ant-menu-item,
  .header0-menu .ant-menu-inline .ant-menu-item {
    height: auto;
    line-height: 1.5;
  }
  .header0-item .item-sub-item,
  .header0-item-child .item-sub-item,
  .header0-menu .item-sub-item {
    display: block;
    padding: 8px 24px;
  }
  .header0-item .item-image,
  .header0-item-child .item-image,
  .header0-menu .item-image {
    float: left;
    margin-right: 16px;
    margin-top: 4px;
    position: relative;
    z-index: 1;
  }
  .header0-item .item-title,
  .header0-item-child .item-title,
  .header0-menu .item-title {
    font-size: 14px;
    color: #fff;
    margin-left: 46px;
  }
  .header0-item .item-content,
  .header0-item-child .item-content,
  .header0-menu .item-content {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    margin-left: 46px;
  }
  .header0 .moreBtn {
    right: 24px;
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header0 .moreBtn .shopBtn {
    font-size: 0.8rem;
    width: 120px;
    height: 46px;
    line-height: 46px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px !important;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff !important;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.footer {
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0 20px;
}
.footer .footer-content {
  position: relative;
  padding-top: 72px;
  max-width: 1440px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(90deg, rgba(63, 0, 237, 0), #7948ea, rgba(63, 0, 237, 0));
  z-index: 0;
}
.footer .footer-content::before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 0px;
  left: 0;
  right: 0;
  background: #000;
  z-index: -1;
}
.footer .footer-content .footer-logo {
  width: 260px;
}
.footer .footer-content .footer-logo img {
  width: 100%;
}
.footer .footer-content .footer-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  width: 704px;
}
.footer .footer-content .footer-nav-text {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.1px;
  color: #e5e5e5;
  text-align: justify;
  vertical-align: top;
  cursor: pointer;
}
.footer .footer-content .footer-nav-subItem {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18.75px;
  color: #808080;
  text-align: justify;
  vertical-align: top;
  cursor: pointer;
}
.footer .footer-content .footer-nav-subItem:last-child {
  margin-bottom: 0;
}
.footer .footer-content .footer-title {
  width: 100%;
  text-align: center;
  font-size: 5rem;
}
.footer .footer-content .footer-title span {
  background-clip: text;
  background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer .footer-content .footer-link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 252px;
}
.footer .footer-content .footer-link .footer-link-list {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.footer .footer-content .footer-link .footer-link-list a img {
  width: 28px;
}
.footer .footer-content .footer-link .footer-link-list a img:nth-child(3) {
  width: 24.7px;
}
.footer .footer-content .footer-link .footer-link-copy {
  width: 100%;
  text-align: right;
  color: #a3a3a3;
}
.footer .footer-content .footer-gitbook {
  margin-top: 98px;
  margin-bottom: 32px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: #808080;
  text-align: center;
}
.footer .footer-content::before {
  position: absolute;
  top: 1px;
  bottom: 0px;
  left: 1px;
  right: 1px;
  background: #000;
  border-radius: 15px;
  content: "";
}
.footer .footer-content:last-child::before {
  bottom: 0px;
}
@media screen and (max-width: 767px) {
  .footer {
    position: relative;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0;
  }
  .footer .footer-content {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: linear-gradient(90deg, rgba(63, 0, 237, 0), #7948ea, rgba(63, 0, 237, 0));
    z-index: 0;
    padding-top: 0;
  }
  .footer .footer-content::before {
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    content: "";
    z-index: -1;
  }
  .footer .footer-content:last-child::before {
    bottom: 0;
  }
  .footer .footer-content .footer-title {
    width: 100%;
    font-size: 1.5rem;
  }
  .footer .footer-content .footer-Subscribe {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .footer .footer-content .footer-Subscribe input {
    width: 337px;
    height: 46px;
    border-radius: 25px;
    background: #333333;
    color: #fff;
    border: none;
    box-shadow: none;
    padding: 0 30px;
    font-size: 1.2rem;
  }
  .footer .footer-content .footer-Subscribe button {
    margin-left: 1rem;
    cursor: pointer;
    border: none;
    color: #000000;
    opacity: 1;
    font-weight: bold;
    font-size: 1.1rem;
    width: 144px;
    height: 46px;
    line-height: 46px;
    border-radius: 25px;
    text-align: center;
    background: linear-gradient(90deg, #37f4a7 0%, #409fed 50%, #a00dff 100%);
  }
  .footer .footer-content .footer-link {
    margin-bottom: 3.92857143vw;
    width: 100%;
  }
  .footer .footer-content .footer-link .footer-link-list {
    margin-bottom: 0;
  }
  .footer .footer-content .footer-gitbook {
    width: 100%;
    display: flex;
    margin: 1.5rem 0 15.11904762vw;
    justify-content: center;
    font-size: 1rem;
    line-height: 3.57142857vw;
    color: #808080;
  }
  .footer .footer-content .footer-gitbook a {
    display: block;
    height: 1rem;
    line-height: 1rem;
    margin: 0 0.5rem;
    font-size: 0.8rem;
    color: #a3a3a3;
  }
  .footer .footer-content .footer-gitbook a:nth-child(2) {
    padding: 0 0.5rem;
    border-top: 0px;
    border-bottom: 0px;
    border-left: 2px;
    border-right: 2px;
    border-style: solid;
    border-color: #fff;
  }
  .footer .footer-content .footer-gitbook a:active {
    color: #a3a3a3;
  }
  .footer .footer-content .footer-gitbook a:visited {
    color: #a3a3a3;
  }
  .footer .footer-content .footer-gitbook a:link {
    color: #a3a3a3;
  }
  .footer .footer-content .footer-logo {
    padding: 6.42857143vw 0 6.78571429vw;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
  .footer .footer-content .footer-logo img {
    width: 59.52380952vw;
  }
  .footer .footer-content .footer-nav {
    margin-top: 0;
    margin-bottom: 16.66666667vw;
  }
  .footer .footer-content .footer-nav .ant-collapse {
    width: 100%;
    border: none;
    font-size: 4.28571429vw;
    line-height: 5vw;
    background: transparent;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item {
    border-top: 1px solid #383838;
    border-bottom: none;
    border-radius: 0;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 12px 0;
    color: #e5e5e5;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item .ant-collapse-header p {
    margin-bottom: 0;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item .ant-collapse-header svg {
    rotate: 90deg;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item .ant-collapse-content {
    border: none;
    background: transparent;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item .ant-collapse-content-box {
    padding: 16px 0;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item-active .ant-collapse-header svg {
    rotate: 180deg;
  }
  .footer .footer-content .footer-nav .ant-collapse .ant-collapse-item:last-child {
    border-bottom: 1px solid #383838;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.litePaper {
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: scroll;
  min-height: 1080px;
  border: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
/* 详细页图片或框框的样式;
*/
button {
  cursor: pointer;
}
.wallet-container1 {
  width: 100%;
  min-height: calc(100vh - 170px);
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wallet-container1 .wallet-title {
  top: 361px;
  width: 100%;
  height: 205px;
  line-height: 100px;
  color: #ffffff;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  font-family: Lato-black;
}
.wallet-container1 .wallet-title p {
  margin: 0;
  padding: 0;
}
.wallet-container1 .wallet-addrInput {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.wallet-container1 .wallet-addrInput input {
  padding: 0 24px;
  width: 30%;
  height: 60px;
  line-height: 26px;
  border-radius: 25px;
  background-color: #ffffff;
  color: #888888;
  font-size: 18px;
  text-align: left;
  font-family: Roboto;
  border: none;
}
.wallet-container1 .wallet-addrInput input:active,
.wallet-container1 .wallet-addrInput input:hover,
.wallet-container1 .wallet-addrInput input:focus,
.wallet-container1 .wallet-addrInput input:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}
.wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
  margin-left: 21px;
  width: 168px;
  height: 60px;
  line-height: 25px;
  border-radius: 30px;
  background-color: #252525;
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid #a985ff;
}
.wallet-container1 .wallet-addrInput .wallet-addrInput-submit.registed {
  border: none;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: #fff;
}
.wallet-container1 .check-pass {
  text-align: center;
  margin-top: 12px;
  font-size: 16px;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: transparent;
  font-weight: bold;
}
.wallet-container1 .wallet-message p {
  text-align: center;
  margin: 40px 0 0;
  width: 100%;
  height: 25px;
  line-height: 23px;
  color: #ffffff;
  font-size: 20px;
  font-family: PingFangSC-regular;
  font-weight: bold;
}
.wallet-container2 {
  width: 100%;
  min-height: 700px;
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wallet-container2 .wallet-card1 {
  width: 634px;
  height: 587px;
  border-radius: 25px;
  background-color: #282828;
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: Roboto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.wallet-container2 .wallet-card1 .wallet-card1-title {
  width: 100%;
  line-height: 85px;
  height: 85px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  font-family: PingFangSC-bold;
}
.wallet-container2 .wallet-card1 .wallet-card1-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 517px;
  margin: 0 auto 20px;
}
.wallet-container2 .wallet-card1 .wallet-card1-info p {
  margin: 0;
  padding: 0;
}
.wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-title {
  height: 39px;
  line-height: 39px;
  color: #fff;
  font-size: 28px;
  text-align: center;
  font-family: PingFangSC-bold;
}
.wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-rank {
  height: 150px;
  line-height: 150px;
  color: #fff;
  font-size: 100px;
  text-align: center;
  font-weight: bold;
  font-family: Roboto-black;
}
.wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated {
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-message {
  height: 52px;
  line-height: 23px;
  color: #a7a7a7;
  font-size: 20px;
  text-align: center;
  font-family: PingFangSC-regular;
}
.wallet-container2 .wallet-card1 .wallet-card1-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;
}
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn {
  width: 348px;
  height: 60px;
  line-height: 25px;
  border-radius: 30px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  color: #fff;
  font-size: 18px;
  text-align: center;
  border: none;
  font-weight: bold;
}
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:active,
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:focus {
  border: none;
  outline: none;
}
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip {
  margin-top: 12px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #646464;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip:active,
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip:focus,
.wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip:hover {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}
.wallet-container3 {
  width: 100%;
  max-width: 1750px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
}
.wallet-container3 .wallet-airdrop {
  width: 80%;
  margin-bottom: 25px;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-top {
  width: 100%;
  border-radius: 25px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  border: none;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-top span {
  font-size: 36px;
  line-height: 44px;
  font-weight: bold;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-top span.wallet-airdrop-top-desc {
  font-size: 18px;
  font-weight: normal;
  padding: 0 8px;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom {
  width: 100%;
  border-radius: 25px;
  background-color: #282828;
  border: none;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p span:first-child {
  line-height: 38px;
  font-size: 28px;
  font-weight: bold;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button {
  min-width: 180px;
  height: 50px;
  line-height: 25px;
  border-radius: 30px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: none;
  color: #ffffff;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:disabled {
  background: linear-gradient(90.11deg, #a0a0a0 3.1%, #646464 100.1%);
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:not(:disabled) {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:not(:disabled):hover {
  color: #000;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:not(:disabled):before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:not(:disabled):hover:before {
  width: 100%;
}
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:not(:disabled):active,
.wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:not(:disabled):focus {
  border: none;
  outline: none;
}
.wallet-container3 .wallet-card2-top {
  width: 80%;
  border-radius: 25px;
  background-color: #282828;
  border: none;
  padding: 30px 40px;
}
.wallet-container3 .wallet-card2-top .card2-top-base {
  color: #fff;
  font-size: 20px;
  text-align: left;
  margin-bottom: 25px;
}
.wallet-container3 .wallet-card2-top .card2-top-base span {
  margin-right: 24px;
}
.wallet-container3 .wallet-card2-top .card2-top-base span:first-child {
  font-weight: bold;
}
.wallet-container3 .wallet-card2-top .card2-top-info {
  display: flex;
  justify-content: space-between;
}
.wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking,
.wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total {
  width: 510px;
}
.wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p,
.wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 28px;
  text-align: left;
}
.wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p:last-child,
.wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p:last-child {
  color: #fff;
  font-size: 86px;
  text-align: left;
  font-weight: bold;
  height: 100px;
  line-height: 100px;
}
.wallet-container3 .wallet-card2-social {
  width: 80%;
  border-radius: 25px;
  background-color: #282828;
  border: none;
  padding: 30px 40px;
  margin-top: 36px;
}
.wallet-container3 .wallet-card2-social .card2-social-title {
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 36px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wallet-container3 .wallet-card2-social .card2-social-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wallet-container3 .wallet-card2-social .card2-social-info button {
  min-width: 120px;
  height: 50px;
  line-height: 25px;
  border-radius: 30px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  font-size: 18px;
  text-align: center;
  border: none;
  color: #ffffff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.wallet-container3 .wallet-card2-social .card2-social-info button:disabled {
  background: #fff;
  color: #000;
}
.wallet-container3 .wallet-card2-social .card2-social-info button:hover {
  color: #000;
}
.wallet-container3 .wallet-card2-social .card2-social-info button:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.wallet-container3 .wallet-card2-social .card2-social-info button:hover:before {
  width: 100%;
}
.wallet-container3 .wallet-card2-social .card2-social-info button:active,
.wallet-container3 .wallet-card2-social .card2-social-info button:focus {
  border: none;
  outline: none;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 15px 25px;
  border-radius: 30px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-title {
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-title span {
  font-size: 18px;
  margin-left: 24px;
  font-weight: normal;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .connect-twitter {
  width: 35%;
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: bold;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter {
  width: 35%;
  height: 60px;
  line-height: 60px;
  color: #282828;
  font-size: 24px;
  font-weight: bold;
  background-color: #e2e2e2;
  border-radius: 30px;
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter span {
  color: blue;
  text-decoration: underline;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right {
  width: 55%;
  color: #b4b4b4;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #282828;
  font-size: 26px;
  font-weight: bold;
  background-color: #e2e2e2;
  border-radius: 30px;
  padding: 0 30px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option span {
  font-size: 28px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option button {
  padding: 0 30px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right p {
  font-size: 18px;
  padding: 0 0 0 30px;
  margin: 8px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right p i {
  margin-left: 8px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other {
  width: 49%;
  background-color: #000;
  color: #fff;
  padding: 15px 25px 25px;
  border-radius: 30px;
  margin-top: 24px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-title {
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  font-size: 26px;
  text-align: left;
  font-weight: bold;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-title span {
  font-size: 18px;
  margin-left: 24px;
  font-weight: normal;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info .other-info-dc {
  font-size: 20px;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info button {
  width: 30%;
  min-width: 120px;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #282828;
  font-size: 26px;
  font-weight: bold;
  background: #e2e2e2;
  border-radius: 30px;
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel:disabled {
  background: #FFF;
  color: #000;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel .other-tel-icon {
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  color: blue;
}
.wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel span {
  color: blue;
  text-decoration: underline;
}
.wallet-container3 .wallet-card2-middle {
  width: 80%;
  border-radius: 25px;
  background-color: #7b7566;
  border: none;
  padding: 30px 40px;
  margin-top: 36px;
}
.wallet-container3 .wallet-card2-middle .card2-middle-title {
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 36px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wallet-container3 .wallet-card2-middle .card2-middle-title .middle-title-icon {
  font-size: 28px;
  margin-left: 24px;
}
.wallet-container3 .wallet-card2-middle .card2-middle-title a {
  color: #fff;
  text-decoration: none;
}
.wallet-container3 .wallet-card2-middle .card2-middle-title a:active,
.wallet-container3 .wallet-card2-middle .card2-middle-title a:hover,
.wallet-container3 .wallet-card2-middle .card2-middle-title a:focus,
.wallet-container3 .wallet-card2-middle .card2-middle-title a:focus-visible {
  text-decoration: none;
  border: none;
  box-shadow: none;
  outline: none;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info {
  display: flex;
  justify-content: space-between;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card {
  width: 45%;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card {
  background-color: #282828;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-radius: 25px;
  padding: 25px;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
  color: #fff;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p:first-child {
  line-height: 26px;
  font-size: 26px;
  font-weight: normal;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon {
  font-weight: normal;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-right {
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  border-radius: 50%;
  color: #282828;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-wrong {
  color: #bd2c0d;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn {
  min-width: 120px;
  height: 46px;
  line-height: 24px;
  border: none;
  border-radius: 30px;
  font-size: 24px;
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  opacity: 1;
  color: #ffffff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:disabled {
  background: #fff;
  color: #000;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover {
  color: #000;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover:before {
  width: 100%;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link {
  margin: 12px 0 0 0;
  color: #eeeeee;
  font-size: 20px;
  text-decoration: underline;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link a {
  color: #fff;
  text-decoration: none;
}
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link a:active,
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link a:hover,
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link a:focus,
.wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link a:focus-visible {
  text-decoration: none;
  border: none;
  box-shadow: none;
  outline: none;
}
.wallet-container3 .wallet-card2-bottom {
  width: 80%;
  border-radius: 25px;
  background-color: #282828;
  border: none;
  padding: 30px 40px;
  margin-top: 36px;
  min-height: 450px;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify {
  margin: 55px auto 0;
  width: 534px;
  height: 231px;
  border-radius: 25px;
  background-color: #646464;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify p {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-family: PingFangSC-regular;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn {
  width: 348px;
  height: 60px;
  line-height: 25px;
  border-radius: 30px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  font-size: 18px;
  text-align: center;
  border: none;
  color: #ffffff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn:disabled {
  background: #fff;
  color: #000;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn:hover {
  color: #000;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn:hover:before {
  width: 100%;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn:active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-verify .bottom-verify-btn:focus {
  border: none;
  outline: none;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-title {
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 36px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info {
  display: flex;
  justify-content: space-between;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right {
  width: 510px;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title {
  height: 39px;
  line-height: 39px;
  color: #fff;
  font-size: 28px;
  text-align: left;
  font-weight: bold;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title .right-title-copy,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title .right-title-copy,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title .right-title-copy,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title .right-title-copy {
  cursor: pointer;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input {
  width: 510px;
  height: 90px;
  border-radius: 50px;
  overflow: hidden;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
  outline: none;
  border: none;
  width: 510px;
  height: 90px;
  background-color: #ffffff;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  letter-spacing: calc(510px / 12);
  color: #2a2a2a;
  padding-left: calc(510px / 12);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:focus,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:focus,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:focus,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:focus,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:active {
  outline: none;
  border: none;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
  font-weight: bold;
  color: #fff;
  border-radius: 25px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg {
  margin-top: 24px;
  height: 55px;
  line-height: 25px;
  color: #c0c0c0;
  font-size: 18px;
  text-align: left;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg p,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg p,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg p,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg p {
  margin: 0;
  padding: 0;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  margin-top: 24px;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn {
  width: 510px;
  height: 60px;
  line-height: 25px;
  border-radius: 30px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  font-size: 18px;
  text-align: center;
  border: none;
  color: #ffffff;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:disabled,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:disabled {
  background: #fff;
  color: #000;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:hover {
  color: #000;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:before,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:hover:before,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:hover:before {
  width: 100%;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:focus,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:focus {
  border: none;
  outline: none;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list {
  display: flex;
  justify-content: center;
  color: #dedede;
  font-size: 18px;
  flex-wrap: wrap;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  line-height: 36px;
  height: 36px;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p:first-child,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p:first-child {
  font-weight: bold;
  font-size: 28px;
  color: #fff;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list .ant-pagination .ant-pagination-item-active,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list .ant-pagination .ant-pagination-item-active {
  color: #fff;
  border: none;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover {
  color: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  border: none;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a {
  color: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center {
  width: 100%;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title {
  height: 39px;
  line-height: 39px;
  color: #fff;
  font-size: 28px;
  text-align: left;
  font-weight: bold;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title .right-title-copy {
  cursor: pointer;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input {
  width: 100%;
  height: 90px;
  border-radius: 25px;
  overflow: hidden;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
  outline: none;
  border: none;
  width: 100%;
  height: 90px;
  background-color: #ffffff;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  letter-spacing: calc(510px / 12);
  color: #fff;
  padding-left: calc(510px / 12);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:hover,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:focus,
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:active {
  outline: none;
  border: none;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
  color: #fff;
  border-radius: 25px;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg {
  margin-top: 24px;
  height: 55px;
  line-height: 25px;
  color: #c0c0c0;
  font-size: 18px;
  text-align: left;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg p {
  margin: 0;
  padding: 0;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list {
  display: flex;
  justify-content: center;
  color: #dedede;
  font-size: 18px;
  flex-wrap: wrap;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  line-height: 36px;
  height: 36px;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p:first-child {
  font-weight: bold;
  font-size: 28px;
  color: #fff;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active {
  color: #fff;
  border: none;
  background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover {
  color: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  border: none;
}
.wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a {
  color: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
}
@media screen and (min-width: 1431px) and (max-width: 1580px) {
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .connect-twitter {
    font-size: 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter {
    font-size: 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option {
    font-size: 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right p {
    font-size: 18px;
    padding: 0 0 0 30px;
    margin: 8px 0 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right p i {
    margin-left: 8px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1430px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .wallet-container1 .wallet-title {
    top: 361px;
    width: 100%;
    height: 120px;
    line-height: 50px;
    color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 24px;
    width: 40%;
    height: 60px;
    line-height: 26px;
    border-radius: 25px;
    background-color: #ffffff;
    color: #888888;
    font-size: 18px;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 21px;
    width: 168px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #29c79a;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wallet-container2 .wallet-card1 {
    width: calc(634px * 0.8);
    height: calc(587px * 0.8);
    border-radius: 25px;
    background-color: #282828;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-title {
    width: 100%;
    line-height: 70px;
    height: 70px;
    background-color: #a6d5b8;
    color: #fff;
    font-size: 28px;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 517px;
    margin: 0 auto 20px;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info p {
    margin: 0;
    padding: 0;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-rank {
    height: 100px;
    line-height: 100px;
    color: #fff;
    font-size: 80px;
    text-align: center;
    font-weight: bold;
    font-family: Roboto-black;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated {
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-message {
    height: 52px;
    line-height: 23px;
    color: #a7a7a7;
    font-size: 20px;
    text-align: center;
    font-family: PingFangSC-regular;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;
    width: 100%;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn {
    width: 40%;
    height: 50px;
    line-height: 25px;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 18px;
    text-align: center;
    border: none;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:active,
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:focus {
    border: none;
    outline: none;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip {
    font-size: 18px;
  }
  .wallet-container3 {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0;
  }
  .wallet-container3 .wallet-card2-top {
    width: 80%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px 40px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base {
    color: #fff;
    font-size: 20px;
    text-align: left;
    margin-bottom: 50px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span {
    margin-right: 24px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total {
    width: 400px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 28px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p:last-child,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p:last-child {
    color: #fff;
    font-size: 86px;
    text-align: left;
    font-weight: bold;
    height: 100px;
    line-height: 100px;
  }
  .wallet-container3 .wallet-card2-social {
    width: 80%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px 40px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info button {
    min-width: 100px;
    line-height: 25px;
    border-radius: 25px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .connect-twitter {
    width: 30%;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter {
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right {
    width: 65%;
    color: #b4b4b4;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 20px;
    font-weight: bold;
    background-color: #e2e2e2;
    border-radius: 30px;
    padding: 0 30px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option span {
    font-size: 28px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option button {
    padding: 0 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right P {
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other {
    width: 49%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info .other-info-dc {
    font-size: 16px;
    max-width: 60%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 26px;
    font-weight: bold;
    background: #e2e2e2;
    border-radius: 30px;
    padding: 0 20px;
    text-align: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel span {
    color: blue;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-middle {
    width: 80%;
    border-radius: 25px;
    background-color: #7b7566;
    border: none;
    padding: 30px 40px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title .middle-title-icon {
    font-size: 28px;
    margin-left: 24px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card {
    width: 48%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card {
    background-color: #282828;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    border-radius: 25px;
    padding: 25px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p {
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-weight: bold;
    line-height: 52px;
    color: #fff;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p:first-child {
    line-height: 26px;
    font-size: 20px;
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon {
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-right {
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    border-radius: 50%;
    color: #282828;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-wrong {
    color: #bd2c0d;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn {
    min-width: 100px;
    height: 46px;
    border: none;
    border-radius: 30px;
    font-size: 20px;
    background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
    opacity: 1;
    color: #ffffff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;
    /*注意图层前后顺序*/
    font-weight: bold;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover {
    color: #000;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1;
    /*注意图层前后顺序*/
    transition: all 0.5s;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover:before {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link {
    margin: 12px 0 0 0;
    color: #eeeeee;
    font-size: 20px;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-bottom {
    width: 80%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px 40px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right {
    width: 400px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input {
    width: 400px;
    height: 70px;
    border-radius: 40px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 400px;
    height: 70px;
    background-color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(400px / 12);
    color: #2a2a2a;
    padding-left: calc(400px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg {
    width: 100%;
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 18px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 30px;
    margin-top: 24px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn {
    width: 400px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p:first-child,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input {
    width: 100%;
    height: 90px;
    border-radius: 25px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(510px / 12);
    color: #fff;
    padding-left: calc(510px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg {
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 18px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active {
    color: #fff;
    border-color: #58b89d;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover {
    color: #58b89d;
    border-color: #58b89d;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a {
    color: #58b89d;
  }
}
@media screen and (min-width: 900px) and (max-width: 1199px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .wallet-container1 .wallet-title {
    top: 200px;
    width: 100%;
    height: 90px;
    line-height: 48px;
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 24px;
    width: 50%;
    height: 60px;
    line-height: 26px;
    border-radius: 25px;
    background-color: #ffffff;
    color: #888888;
    font-size: 18px;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 21px;
    width: 168px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #29c79a;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    padding: 3rem 0;
  }
  .wallet-container3 {
    width: 100%;
    padding: 40px 0;
    min-height: 100vh;
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-airdrop {
    width: 90%;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top {
    width: 100%;
    padding: 30px;
    margin-bottom: 25px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top span {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top span.wallet-airdrop-top-desc {
    font-size: 16px;
    font-weight: normal;
    padding: 0 8px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom {
    width: 100%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p span:first-child {
    line-height: 38px;
    font-size: 24px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button {
    min-width: 180px;
    height: 50px;
    line-height: 25px;
    border-radius: 30px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    font-size: 18px;
    text-align: center;
    border: none;
    color: #ffffff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;
    /*注意图层前后顺序*/
    font-weight: bold;
    overflow: hidden;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:hover {
    color: #000;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1;
    /*注意图层前后顺序*/
    transition: all 0.5s;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:hover:before {
    width: 100%;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:active,
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button:focus {
    border: none;
    outline: none;
  }
  .wallet-container3 .wallet-card2-top {
    width: 90%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base {
    color: #fff;
    font-size: 20px;
    text-align: left;
    margin-bottom: 50px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span {
    margin-right: 24px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total {
    width: 350px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 24px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p:last-child,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p:last-child {
    color: #fff;
    font-size: 60px;
    text-align: left;
    font-weight: bold;
    height: 120px;
    line-height: 120px;
  }
  .wallet-container3 .wallet-card2-social {
    width: 90%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px 40px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info button {
    min-width: 100px;
    line-height: 25px;
    border-radius: 25px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .connect-twitter {
    width: 40%;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter {
    width: 40%;
    font-size: 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right {
    margin-top: 12px;
    width: 80%;
    color: #b4b4b4;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 20px;
    font-weight: bold;
    background-color: #e2e2e2;
    border-radius: 30px;
    padding: 0 30px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option span {
    font-size: 28px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option button {
    padding: 0 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right P {
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other {
    width: 48%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info .other-info-dc {
    margin-top: 12px;
    font-size: 16px;
    max-width: 80%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 18px;
    font-weight: bold;
    background: #e2e2e2;
    border-radius: 30px;
    padding: 0 20px;
    text-align: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel span {
    color: blue;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-middle {
    width: 90%;
    border-radius: 25px;
    background-color: #7b7566;
    border: none;
    padding: 30px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title .middle-title-icon {
    font-size: 28px;
    margin-left: 24px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card {
    width: 49%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card {
    background-color: #282828;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    border-radius: 25px;
    padding: 25px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p {
    margin: 0;
    padding: 0;
    font-size: 26px;
    font-weight: bold;
    line-height: 52px;
    color: #fff;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p:first-child {
    line-height: 16px;
    font-size: 16px;
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon {
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-right {
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    border-radius: 50%;
    color: #282828;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-wrong {
    color: #bd2c0d;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn {
    min-width: 80px;
    height: 36px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
    opacity: 1;
    color: #ffffff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;
    /*注意图层前后顺序*/
    font-weight: bold;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover {
    color: #000;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1;
    /*注意图层前后顺序*/
    transition: all 0.5s;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover:before {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link {
    margin: 12px 0 0 0;
    color: #eeeeee;
    font-size: 16px;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-bottom {
    width: 90%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right {
    width: 350px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input {
    width: 350px;
    height: 70px;
    border-radius: 40px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 350px;
    height: 70px;
    background-color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(350px / 12);
    color: #2a2a2a;
    padding-left: calc(350px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg {
    width: 100%;
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 18px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 30px;
    margin-top: 24px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn {
    width: 350px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p:first-child,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input {
    width: 100%;
    height: 90px;
    border-radius: 25px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(510px / 12);
    color: #fff;
    padding-left: calc(510px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg {
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 18px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active {
    color: #fff;
    border-color: #58b89d;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover {
    color: #58b89d;
    border-color: #58b89d;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a {
    color: #58b89d;
  }
}
@media screen and (min-width: 769px) and (max-width: 899px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .wallet-container1 .wallet-title {
    top: 200px;
    width: 100%;
    height: 72px;
    line-height: 36px;
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 24px;
    width: 50%;
    height: 60px;
    line-height: 26px;
    border-radius: 25px;
    background-color: #ffffff;
    color: #888888;
    font-size: 18px;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 21px;
    width: 168px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #29c79a;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    padding: 3rem 0;
  }
  .wallet-container3 {
    width: 100%;
    padding: 40px 0;
    min-height: 100vh;
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-airdrop {
    width: 95%;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top {
    width: 100%;
    padding: 30px;
    margin-bottom: 25px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top span {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top span.wallet-airdrop-top-desc {
    font-size: 14px;
    font-weight: normal;
    padding: 0 8px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom {
    padding: 30px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p span:first-child {
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button {
    min-width: 150px;
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-top {
    width: 95%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base {
    color: #fff;
    font-size: 20px;
    text-align: left;
    margin-bottom: 50px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span {
    margin-right: 24px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total {
    width: 350px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 24px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p:last-child,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p:last-child {
    color: #fff;
    font-size: 60px;
    text-align: left;
    font-weight: bold;
    height: 120px;
    line-height: 120px;
  }
  .wallet-container3 .wallet-card2-social {
    width: 95%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info button {
    min-width: 100px;
    line-height: 25px;
    border-radius: 25px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .connect-twitter {
    width: 50%;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter {
    width: 50%;
    font-size: 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right {
    margin-top: 12px;
    width: 100%;
    color: #b4b4b4;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 16px;
    font-weight: bold;
    background-color: #e2e2e2;
    border-radius: 30px;
    padding: 0 30px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option span {
    font-size: 24px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option button {
    padding: 0 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right P {
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other {
    width: 48%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info .other-info-dc {
    margin-top: 12px;
    font-size: 16px;
    max-width: 70%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 16px;
    font-weight: bold;
    background: #e2e2e2;
    border-radius: 30px;
    padding: 0 10px;
    text-align: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel span {
    color: blue;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-middle {
    width: 95%;
    border-radius: 25px;
    background-color: #7b7566;
    border: none;
    padding: 30px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 36px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title .middle-title-icon {
    font-size: 28px;
    margin-left: 24px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card {
    width: 49%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card {
    background-color: #282828;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    border-radius: 25px;
    padding: 20px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 48px;
    color: #fff;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p:first-child {
    line-height: 14px;
    font-size: 14px;
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon {
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-right {
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    border-radius: 50%;
    color: #282828;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-wrong {
    color: #bd2c0d;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn {
    min-width: 80px;
    height: 36px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
    opacity: 1;
    color: #ffffff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;
    /*注意图层前后顺序*/
    font-weight: bold;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover {
    color: #000;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1;
    /*注意图层前后顺序*/
    transition: all 0.5s;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover:before {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link {
    margin: 12px 0 0 0;
    color: #eeeeee;
    font-size: 16px;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-bottom {
    width: 95%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 30px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info {
    display: flex;
    justify-content: space-between;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right {
    width: 350px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input {
    width: 300px;
    height: 70px;
    border-radius: 40px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 300px;
    height: 70px;
    background-color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(300px / 12);
    color: #2a2a2a;
    padding-left: calc(300px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg {
    width: 100%;
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 14px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 30px;
    margin-top: 24px;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn {
    width: 300px;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p:first-child,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 22px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input {
    width: 100%;
    height: 90px;
    border-radius: 25px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(510px / 12);
    color: #fff;
    padding-left: calc(510px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg {
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 16px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active {
    color: #fff;
    border-color: #58b89d;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover {
    color: #58b89d;
    border-color: #58b89d;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a {
    color: #58b89d;
  }
}
@media screen and (max-width: 768px) {
  .wallet-container1 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .wallet-container1 .wallet-title {
    top: 100px;
    width: 100%;
    height: 6rem;
    line-height: 3rem;
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    font-family: Lato-black;
  }
  .wallet-container1 .wallet-title p {
    margin: 0;
    padding: 0;
  }
  .wallet-container1 .wallet-addrInput {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
  }
  .wallet-container1 .wallet-addrInput input {
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    line-height: 3rem;
    border-radius: 2.8rem;
    background-color: #ffffff;
    color: #888888;
    font-size: 1rem;
    text-align: left;
    font-family: Roboto;
    border: none;
  }
  .wallet-container1 .wallet-addrInput input:active,
  .wallet-container1 .wallet-addrInput input:hover,
  .wallet-container1 .wallet-addrInput input:focus,
  .wallet-container1 .wallet-addrInput input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .wallet-container1 .wallet-addrInput .wallet-addrInput-submit {
    margin-left: 0;
    margin-top: 1rem;
    width: 50%;
    height: 3rem;
    line-height: 25px;
    border-radius: 30px;
    background-color: #252525;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    border: 1px solid #29c79a;
  }
  .wallet-container1 .wallet-message p {
    text-align: center;
    margin: 40px 0 0;
    width: 100%;
    height: 25px;
    line-height: 23px;
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-regular;
    font-weight: bold;
  }
  .wallet-container2 {
    width: 100%;
    min-height: calc(100vh - 170px);
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
  }
  .wallet-container2 .wallet-card1 {
    width: 95%;
    height: 480px;
    border-radius: 30px;
    background-color: #282828;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-title {
    width: 100%;
    line-height: 4rem;
    height: 4rem;
    background-color: #a6d5b8;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 517px;
    margin: 0 auto 20px;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info p {
    margin: 0;
    padding: 0;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-title {
    height: 1.5rem;
    line-height: 1.5rem;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    font-family: PingFangSC-bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-rank {
    height: 8rem;
    line-height: 8rem;
    color: #fff;
    font-size: 4rem;
    text-align: center;
    font-weight: bold;
    font-family: Roboto-black;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-estimated {
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-info .card1-info-message {
    padding: 1rem;
    height: 3rem;
    line-height: 1.5rem;
    color: #a7a7a7;
    font-size: 1rem;
    text-align: center;
    font-family: PingFangSC-regular;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;
    width: 100%;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn {
    width: 40%;
    height: 50px;
    line-height: 25px;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 1rem;
    text-align: center;
    border: none;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:active,
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:focus,
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container2 .wallet-card1 .wallet-card1-option .card1-option-skip {
    font-size: 1.2rem;
  }
  .wallet-container3 {
    width: 100%;
    padding: 20px 0;
    min-height: 100vh;
    background-color: #000;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-airdrop {
    width: 95%;
    margin-bottom: 32px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top {
    width: 100%;
    padding: 20px;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top span {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: bold;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-top span.wallet-airdrop-top-desc {
    font-size: 14px;
    font-weight: normal;
    padding: 0 8px;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p {
    font-size: 1rem;
    font-weight: normal;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p span:first-child {
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-airdrop .wallet-airdrop-bottom p button {
    margin-top: 1rem;
    min-width: 150px;
    font-size: 1.3;
  }
  .wallet-container3 .wallet-card2-top {
    width: 95%;
    border-radius: 30px;
    background-color: #282828;
    border: none;
    padding: 20px;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base {
    color: #fff;
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 2.5rem;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span {
    margin-right: 1.2rem;
  }
  .wallet-container3 .wallet-card2-top .card2-top-base span:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info {
    display: flex;
    justify-content: space-around;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total {
    width: 50%;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 1.3rem;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-ranking p:last-child,
  .wallet-container3 .wallet-card2-top .card2-top-info .card2-info-total p:last-child {
    color: #fff;
    font-size: 3rem;
    text-align: left;
    font-weight: bold;
    height: 5rem;
    line-height: 5rem;
  }
  .wallet-container3 .wallet-card2-social {
    width: 95%;
    border-radius: 25px;
    background-color: #282828;
    border: none;
    padding: 20px;
    margin-top: 36px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 2rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info button {
    min-width: 100px;
    line-height: 25px;
    border-radius: 25px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-title {
    font-size: 1.5rem;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .connect-twitter {
    width: 100%;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .follow-twitter {
    width: 100%;
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right {
    margin-top: 12px;
    width: 100%;
    color: #b4b4b4;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    line-height: 30px;
    color: #282828;
    font-size: 16px;
    font-weight: bold;
    background-color: #e2e2e2;
    border-radius: 30px;
    padding: 10px 30px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option span {
    font-size: 24px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right .twitter-right-option button {
    padding: 0 20px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-twitter .social-twitter-info .twitter-info-right P {
    font-size: 16px;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-title {
    font-size: 1.5rem;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info .other-info-dc {
    margin-top: 12px;
    font-size: 16px;
    max-width: 100%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-info button {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #282828;
    font-size: 16px;
    font-weight: bold;
    background: #e2e2e2;
    border-radius: 30px;
    padding: 0 10px;
    text-align: center;
  }
  .wallet-container3 .wallet-card2-social .card2-social-info .social-info-other .social-other-tel span {
    color: blue;
    text-decoration: underline;
  }
  .wallet-container3 .wallet-card2-middle {
    width: 95%;
    border-radius: 25px;
    background-color: #7b7566;
    border: none;
    padding: 20px;
    margin-top: 1rem;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 2rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-title .middle-title-icon {
    font-size: 1.2rem;
    margin-left: 24px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card {
    background-color: #282828;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-radius: 25px;
    padding: 16px;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: bold;
    line-height: 44px;
    color: #fff;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p:first-child {
    line-height: 14px;
    font-size: 14px;
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon {
    font-weight: normal;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-right {
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    border-radius: 50%;
    color: #282828;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .middle-card-icon-wrong {
    color: #bd2c0d;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn {
    min-width: 80px;
    height: 30px;
    border: none;
    border-radius: 30px;
    font-size: 14px;
    background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
    opacity: 1;
    color: #ffffff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    position: relative;
    cursor: pointer;
    z-index: 0;
    /*注意图层前后顺序*/
    font-weight: bold;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover {
    color: #000;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:before {
    content: '';
    width: 0;
    border-radius: 30px;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    z-index: -1;
    /*注意图层前后顺序*/
    transition: all 0.5s;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .middle-card p .card2-middle-btn:hover:before {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-middle .card2-middle-info .card2-middle-card .card2-middle-Link {
    margin: 12px 0 0 0;
    color: #eeeeee;
    font-size: 16px;
    text-decoration: underline;
  }
  .wallet-container3 .card2-bottom-verify {
    margin: 55px auto 0;
    width: 95% !important;
    height: 231px;
    border-radius: 25px;
    background-color: #646464;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .wallet-container3 .card2-bottom-verify p {
    color: #ffffff;
    font-size: 1.2rem !important;
    text-align: center;
    font-family: PingFangSC-regular;
  }
  .wallet-container3 .card2-bottom-verify .bottom-verify-option {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wallet-container3 .card2-bottom-verify .bottom-verify-option .bottom-verify-btn {
    width: 60% !important;
    height: 60px;
    line-height: 25px;
    border-radius: 30px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border: none;
  }
  .wallet-container3 .card2-bottom-verify .bottom-verify-option .bottom-verify-btn:active,
  .wallet-container3 .card2-bottom-verify .bottom-verify-option .bottom-verify-btn:focus,
  .wallet-container3 .card2-bottom-verify .bottom-verify-option .bottom-verify-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container3 .wallet-card2-bottom {
    width: 95%;
    border-radius: 30px;
    background-color: #282828;
    border: none;
    padding: 20px;
    margin-top: 1rem;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-title {
    height: 3rem;
    line-height: 3rem;
    color: #fff;
    font-size: 2rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right {
    width: 20rem;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title {
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    font-size: 1.4rem;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-title .right-title-copy,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input {
    width: 20rem;
    height: 4rem;
    border-radius: 2rem;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 20rem;
    height: 4rem;
    background-color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(20rem / 12);
    color: #2a2a2a;
    padding-left: calc(20rem / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-input input,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-input input {
    color: #fff;
    border-radius: 2rem;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg {
    width: 100%;
    margin-top: 24px;
    height: 3rem;
    line-height: 1.2rem;
    color: #fff;
    font-size: 1rem;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-msg p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    margin-top: 2.2rem;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn {
    width: 20rem;
    height: 3rem;
    line-height: 25px;
    border-radius: 30px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:active,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-left-option .card2-option-btn:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-left-option .card2-option-btn:hover {
    border: none;
    outline: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-left .card2-bottom-right-list p:first-child,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-right .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center {
    width: 100%;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title {
    height: 39px;
    line-height: 39px;
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-title .right-title-copy {
    cursor: pointer;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input {
    width: 100%;
    height: 90px;
    border-radius: 25px;
    overflow: hidden;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    outline: none;
    border: none;
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    letter-spacing: calc(510px / 12);
    color: #fff;
    padding-left: calc(510px / 12);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:hover,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:focus,
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input:active {
    outline: none;
    border: none;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-input input {
    color: #fff;
    border-radius: 25px;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg {
    margin-top: 24px;
    height: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 18px;
    text-align: left;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-msg p {
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    flex-wrap: wrap;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list p:first-child {
    font-weight: bold;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active {
    color: #fff;
    border-color: #58b89d;
    background: linear-gradient(90.11deg, #a985ff 3.1%, #1d3bd5 100.1%);
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover {
    color: #58b89d;
    border-color: #58b89d;
  }
  .wallet-container3 .wallet-card2-bottom .card2-bottom-info .card2-bottom-center .card2-bottom-right-list .ant-pagination .ant-pagination-item:hover a {
    color: #58b89d;
  }
}
.infoLine {
  width: 300px;
  height: 100%;
  background-color: #191919;
  float: left;
  position: absolute;
  left: 0;
}
.collapsed {
  width: 0px;
}
.collapse-btn {
  margin-top: 15px;
  margin-left: 15px;
}
.info-item {
  margin: 20px;
  background-color: #222222;
  padding: 20px;
  border-radius: 5px;
}
.hidden {
  display: none;
}
.info-item-title {
  background-clip: text;
  background: linear-gradient(90deg, #37f4a7, #40a0ee, #9e0dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sub {
  vertical-align: sub;
  font-size: smaller;
}
.info-item-data {
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
}
.mapContainer {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.mapContainer.expanded {
  margin-left: 300px;
}
.anchorBL {
  display: none;
}
.ec-extension-bmap {
  height: calc(100vh - 64px) !important;
}
@media screen and (max-width: 767px) {
  .infoLine {
    width: 230px;
  }
  .mapContainer {
    width: 100%;
    height: calc(100vh - 64px);
  }
  .mapContainer.expanded {
    margin-left: 230px;
  }
  .title {
    font-size: 20px;
    max-width: 80vw;
  }
  .text {
    font-size: 14px;
    max-width: 80vw;
  }
  .img img {
    width: 50vw;
  }
  .mapbox-map .ant-drawer-content {
    position: static;
    width: 100%;
    padding: 0 !important;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #000;
  }
  .mapbox-map .ant-drawer-content .ant-drawer-wrapper-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mapbox-map .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .mapbox-map .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .info-item {
    width: 19.5%;
    margin: 0px;
    padding: 8px 0;
    text-align: center;
  }
  .mapbox-map .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .info-item .info-item-title {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    height: 32px;
  }
  .mapbox-map .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .info-item .info-item-data {
    font-size: 18px;
  }
  .mapbox-map .show-drawer {
    position: relative;
    top: 1rem;
    left: 20px;
    z-index: 2000;
    background: linear-gradient(45deg, #37f4a7, #40a0ee, #9e0dff);
    color: #fff;
    border: none;
  }
}

/* 变量 Variables */
/* 按钮颜色 */
/* 文字颜色 */
/* hover色块颜色 */
/* 主题颜色 */
/* pc最小宽度 */
/* pc最大宽度 */
/* 混合 Mixins */
/* pc最大宽度 */
.min-width {
  min-width: 1280px;
}
/* pc最大宽度 */
.max-width {
  max-width: 1440px;
}
/* 按钮渐变 */
.btn-gradient {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, #9a47ff, #3f00ed);
  border: solid 1px transparent;
}
/* 实心按钮渐变 */
.btn-gradient-solid {
  background: linear-gradient(90deg, #a985ff 0%, #1d3bd5 100%);
  border: none;
}
/* 大标题 */
.main-title {
  font-size: 56px;
  font-weight: 1000;
}
.btn-before {
  content: '';
  width: 0;
  border-radius: 30px;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #000;
  z-index: -1;
  /*注意图层前后顺序*/
  transition: all 0.5s;
}
.btn-need-hover {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  position: relative;
  cursor: pointer;
  z-index: 0;
  /*注意图层前后顺序*/
  font-weight: bold;
  overflow: hidden;
}
.legal-page {
  position: relative;
  padding-top: 118px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  max-width: 1440px;
  margin: 20px auto;
  padding: 0 20px;
}
.legal-page .legal-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  height: 80px;
  line-height: 80px;
}
.legal-page .legal-collapse {
  background-color: #282828;
  border-radius: 25px;
  border: none;
  color: #fff;
  text-align: left;
}
.legal-page .legal-collapse .ant-collapse-header {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  height: 60px;
}
.legal-page .legal-collapse .ant-collapse-item:last-child {
  border-bottom: none;
}
.legal-page .legal-collapse .ant-collapse-content {
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.8);
}
.legal-page .legal-collapse .legal-panel {
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .legal-page {
    position: relative;
    padding-top: 118px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    max-width: 1440px;
    margin: 20px auto;
    padding: 0 20px;
  }
  .legal-page .legal-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    height: 3rem;
    line-height: 3rem;
  }
  .legal-page .legal-collapse {
    background-color: #282828;
    border-radius: 20px;
    border: none;
    color: #fff;
    text-align: left;
  }
  .legal-page .legal-collapse .ant-collapse-header {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    line-height: 3rem;
    height: 3rem;
    padding: 0;
    padding-left: 40px;
  }
  .legal-page .legal-collapse .ant-collapse-item:last-child {
    border-bottom: none;
  }
  .legal-page .legal-collapse .ant-collapse-content {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.8);
  }
  .legal-page .legal-collapse .legal-panel {
    font-size: 1rem;
  }
}

